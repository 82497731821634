.testimonials{
    .cbTitle {
        text-align: center;
        color: #fff;
        padding-bottom: 20px;
    }
    .testimonial-wrapper{
        text-align: center;
        width: 100%;
        padding: 50px 0;
       .asset{
           background-color: #fff;
           width: 200px;
           height: 200px;
           border-radius: 100px;
           overflow: hidden;
           margin: 0 auto;
       }
       .description{
           padding: 20px 100px 0px;
            @media screen and (max-width: "600px") {
                padding: 20px 30px 0;
            }
           p {
               color: #fff;
               line-height: 1.9rem;
               letter-spacing: 0.02rem;
           }
       }
       .profile{
            color: #fff;
            @media screen and (max-width: "600px") {
                padding: 0px 0px 20px;
            }
            .name{
                font-weight: 900;
                font-size: 16px;
            }
            .company{
                font-weight: 900;
                font-size: 16px;
                opacity: .8;
                font-style: italic;
            }
        }
    }
}

.testimonials{
    .carousel-component{
        &[data-device="desktop"]{
            .carousel-component__bullets{
                margin-top:  54px !important;
            }
        }
    }
}
.campaignBanner{
    max-width: 100%;
    overflow: hidden;
    .productBannerCopyHolder{
        .productBannerCopy {
            top: 70%;
            .copy {
                margin: 0.8rem 0;
                h1{
                    font-size: 2.4rem;
                    line-height: 48px;
                }
                h1, h6{
                    color: white;
                    &.dark{
                        p {
                            color: black;
                        }
                    }
                    p {
                        color: white;
                        
                    }
                }
            }

            .buttons{
                a{
                    &.btnWhite{
                        border: white 1px solid !important;
                        color: white !important;
                    }
                    
                }
            }
        }
    }
}

.campaign-layout {
    @media(max-width: 991px) {
        .banner .productBannerCopyHolder {
            padding-top: 0;
            .buttons {
                margin-bottom: 15px;
            }
        }
    }
}

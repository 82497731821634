.legalHolder{
	&:first-child{
		margin-top: 55px;
	}
	&:last-child{
		margin-bottom: 55px;
	}
	.featuredLegalCard{
		.legalFeatured{
			.readMore{
				color: $honda-red;
				font-family: $montserrat;
				margin-top: 25px;
				display: block;
			}
		}
	}
}

.legalBuilderHolder{
	.copy{
		a{
			color: $dark-grey;
			text-decoration: underline;
			&:hover{
				color: $honda-red;
			}
		}
	}

	.goBack{
		color: $honda-red;
		text-transform: uppercase;
		font-family: $montserrat;
		font-weight: 700;
		&:hover{
			text-decoration: underline;
		}
	}
}
footer {
	font-family: $montserrat;
	h5 {
		font-size: 16px;
	}

	a {
		font-size: 12px;
		font-weight: 900;
		text-transform: capitalize;
		color: white;
		display: block;
		margin: 15px 0;
		transition: color 0.3s ease-in-out;
		&:hover{
			color: $honda-red;
		}
		&.default{
			display: inline-block;
			color: #bdbdbd;
			margin-left: 2px;
			&:hover{
				color: $honda-red;
			}
		}
		&.buttonRed{
			&:hover{
				color: $dark-grey;
			}
		}
	}

	.page-footer {
		.container {
			.row {
				.col {
					.footerHeaderTitle {
						font-size: 100px;
					}

					a {
						font-family: $montserrat;
						font-size: 12px;
						text-decoration: none;
						text-transform: capitalize;
						color: white;
						display: block;

						.footer_honda_array {
							text-transform: capitalize;
							text-decoration: none;
						}
					}
				}
			}
		}
	}

	.footer-social-wrapper {
		.footer_social_icons {
			display: block;
			text-decoration: none;

			a{
				font-size: 14px;
			}

			svg.switched-icon {	
				width: 28px;
				height: auto;
				vertical-align: bottom;
			}

			.footer_social_icon{
				vertical-align: middle;
				display: inline-block;
				// margin: 0;
				margin-right: 20px;
				font-size: 20px;
				transition: 0.3s ease-in-out;

				&:hover{
					color: $honda-red !important;
				}
			}
		}
		background-color: transparent !important;
	}
}

.footerHeaderImg {
	margin: ($h5-fontsize / 1.5) 0 ($h5-fontsize / 2.5) 0;
	height: 30px;
	img {
		height: 100%;
	}
}

.hrc {
	&[data-device="tablet"], &[data-device="mobile"] {
		padding-bottom: 25px;
    	margin: 0 20px 25px;
		height: 70px;
		.col:nth-child(1) {
			display: flex;
			flex-direction: column;
			align-items: center;
			padding-right: 10px;
		}
		.col:nth-child(2) {
			padding-left: 10px;
			// justify-content: center;
			// display: flex;
		}
	}
}

.hrc-social {
	display: flex;
		display: -webkit-flex;
    	display: -moz-box;
	a {
		display: inline-block;
		margin: 0;
		margin-right: 16px;
		padding-top: 8px;
		font-size: 16px;
		-webkit-transition: 0.3s ease-in-out;
		-moz-transition: 0.3s ease-in-out;
		transition: 0.3s ease-in-out;
		color: #bdbdbd;
	}
	&[data-device="mobile"] {
		padding-top: 5px;
		a {
			
			width: 45px !important;
			svg {
				width: 30px;
				height: 30px;
			}
		}
	}
	&[data-device="tablet"] {
		a {
			width: 45px !important;
			svg {
				width: 40px;
				height: 40px;
			}
		}
	}
}
.generalNav {
	height: 45px;
	.nav-extended {
		background-color: transparent;
		.nav-wrapper {
			background-color: $primary_navigation_backgroundColor;
			min-height: 45px;
			padding: 0 10%;
			.navBlockItem {
				line-height: 45px;
			}
		}
		.nav-content {
			background-color: rgba(
				$color: $primary_navigation_backgroundColor,
				$alpha: 0.65
			);
			
			padding: 0 10%;
			height: 60px;
			.navBlockItem {
				line-height: 60px;
				font-weight: 700;
				&.logo {
					display: flex;
					height: 60px;
					.brand-logo {
						position: relative;
						display: inline-flex;
					}
				}
			}
		}

		@supports ( backdrop-filter: blur(10px) ) or ( -webkit-backdrop-filter: blur(10px) )  {
			.nav-content {
				background-color: rgba(
					$color: $primary_navigation_backgroundColor,
					$alpha: 0.5
				);
				backdrop-filter: blur(10px);
				-webkit-backdrop-filter: blur(10px);
			}
		}

		.navBlock {
			.navBlockItem {
				font-family: $montserrat;
				display: inline-block;
				text-decoration: none;
				margin-right: 20px;
				cursor: pointer;
				transition: 0.1s ease-in;
				text-transform: uppercase;
				a {
					&:hover {
						background-color: transparent;
					}
					transition: 0.1s ease-in;
				}
				&:hover {
					color: $primary_default_button;
					a {
						color: $primary_default_button;
					}
				}
			}
		}
	}
}

.productNav {
	height: 0 !important;
	nav {
		background-color: transparent;
		
		.nav-wrapper {
			background-color: rgba(
				$color: $primary_navigation_backgroundColor,
				$alpha: 0.65
			);
			padding: 0 7.5%;
		}

		@supports ( backdrop-filter: blur(10px) ) or ( -webkit-backdrop-filter: blur(10px) )  {
			.nav-wrapper {
				background-color: rgba(
					$color: $primary_navigation_backgroundColor,
					$alpha: 0.5
				);
				backdrop-filter: blur(10px);
				-webkit-backdrop-filter: blur(10px);
			}
		}

		.navBlock {
			.navBlockItem {
				font-family: $montserrat;
				text-decoration: none;
				cursor: pointer;
				transition: 0.1s ease-in;
				text-transform: uppercase;
				font-weight: 700;
				svg {
					margin-left: 5px;
				}
				&:hover {
					color: $primary_default_button;
				}
			}
		}
	}
}

.navBuilderHolder {
	margin-bottom: 32px;
	z-index: 99999999;
	.navBuilder {
		background-color: $dark-grey;
		width: 100%;
		margin: 0 auto;
		text-align: center;
		&.nav-pinned {
			width: 100%;
			z-index: 250;
			position: fixed;
			top: 0;
			left: 0;
		}
		.navBuilderItem {
			display: inline-block;
			margin: 0 32px;
			color: white;
			a {
				color: #d8d8d8;
				font-size: 12px;
				text-transform: uppercase;
				display: block;
				padding-top: 20px;
				padding-bottom: 16px;
				border-bottom: 4px solid transparent;
				transition: border 0.5s;
				&:hover {
					border-bottom-color: #fff;
				}
				&.active {
					border-bottom-color: #fff;
				}
			}
		}
	}
}

.navbar-fixed {
	.nav-wrapper {
		.navBlock {
			.navBlockItem {
				position: relative;
				&.navDropdown {
					padding: 0 15px;
					transition: 0.3s ease-in;
					.dropdownList {
						position: absolute;
						top: 100%;
						right: 0;
						background-color: rgba(
							$color: $primary_navigation_backgroundColor,
							$alpha: 0.5
						);
						z-index: 999;
						overflow: hidden;
						li {
							width: 100%;
							transition: 0.1s ease-in;
							white-space: nowrap;
							a {
								transition: 0.1s ease-in;
								.line {
									width: 100%;
									border-bottom: 2px solid white;
								}
							}
							&:hover {
								color: $primary_default_button;
								a {
									color: $primary_default_button;
								}
							}
						}
					}
					&:hover {
						background-color: rgba(0, 0, 0, 0.1);
					}
				}
				.navItem {
					a {
						transition: 0.1s ease-in;
					}
					&:hover {
						color: $primary_default_button;
						a {
							color: $primary_default_button;
						}
					}
				}
			}
		}
	}
}

.sideNavHolder {
	position: fixed;
	left: 1%;
	bottom: 2%;
	z-index: 1000;
	.sideNavItem {
		position: relative;
		margin-bottom: 20px;
		display: table;
		border-radius: 100px;
		background-color: $white-grey;
		border: 4px solid $light-grey;
		width: fit-content;
		transition: width 0.3s ease-in-out;
		margin-right: auto;
		.icon {
			width: 70px;
			height: 70px;
			color: $dark-grey;
			font-size: 1.8em;
			line-height: 60px;
			text-align: center;
			display: flex;
			align-items: center;
			justify-content: center;
			transition: 0.3s ease-in-out;
			float: left;
		}
		&:hover {
			.content {
				padding-right: 30px !important;
				padding-top: 18px !important;
			}
		}
		.content {
			float: right;
			overflow: hidden;
			white-space: nowrap;
			opacity: 0; 
			height: 70px;
			max-height: 70px;
			display: block;
			justify-content: left;
			align-items: center;
			color: $dark-grey;
			p{
				padding: 7px 0;
			}
		}
		&.recall {
			background-color: $honda-red;
			border-color: $honda-red;
			.icon {
				margin: 0;
				transition: 0.3s ease-in-out;
				img {
					width: 90%;
					height: auto;
				}
			}
			.content {
				color: white;
				display: block;
				max-height: unset;
				height: unset;
				.title {
					margin: 0;
				}
			}
			&:hover {
				.icon {
					margin: 15px 0;
					padding-top: 20px;
					padding-bottom: 20px;
				}
				.content {
					padding-right: 30px !important;
					padding-left: 20px !important;
					padding-top: 18px !important;
				}
			}
		}

		&:hover {
			width: auto;
			.icon {
				font-size: 2.5em;
			}
			.content {
				opacity: 1;
				padding-right: 20px;
				padding-left: 0;
			}
		}
		&:after {
			content: "";
			display: table;
			clear: both;
		}
		&:last-child {
			margin-bottom: 0;
		}
	}
}

.filterNavHolder {
	margin-bottom: 32px;
	z-index: 99999999;
	.navBuilder {
		background-color: $dark-grey;
		width: 100%;
		padding: 0 7.5%;
		margin: 0 auto;
		text-align: center;
		&.nav-pinned {
			width: 100%;
			z-index: 250;
			position: fixed;
			top: 0;
			left: 0;
		}
		.navBuilderItem {
			display: inline-block;
			color: white;
			border-bottom: 4px solid transparent;
			transition: border 0.5s;
			a {
				color: #d8d8d8;
				font-size: 12px;
				text-transform: uppercase;
				display: block;
				padding: 0 32px;
				padding-top: 20px;
				padding-bottom: 16px;
				cursor: pointer;
			}
			&.active {
				border-bottom-color: #fff;
			}
			&:hover {
				border-bottom-color: #fff;
			}
			&.active {
				border-bottom-color: #fff;
			}
		}

		.navTitle {
			display: inline-block;
			margin: 0 32px;
			color: white;
			color: #d8d8d8;
			font-size: 12px;
			text-transform: uppercase;
			padding-top: 20px;
			padding-bottom: 16px;
			border-bottom: 4px solid transparent;
		}
	}
}

.searchShade {
	position: fixed;
	background: rgba(0, 0, 0, 0.8);
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	
	// &.enter{
	// 	z-index: 99999999999;
	// }
	// &.exit{
	// 	z-index: -4;
	// }
}

.searchModel {
	position: fixed;
	// width: 500px;
	background: white;
	border-radius: 10px;
	z-index: 99999999999;
	top: 50%;
	left: 50%;
	.close {
		position: absolute;
		top: 5px;
		right: 10px;
		cursor: pointer;
		transition: 0.3s ease-in;
		&:hover {
			transform: rotate(90deg);
		}
	}
	// &.enter{
	// 	z-index: 99999999999;
	// }
	// &.exit{
	// 	z-index: -4;
	// }
}

nav{
	.brand-logo{
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
	}
}

@media (max-width: 480px) {
	.searchModel {
		width: 350px;
	}

  
}
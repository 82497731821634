.accordion{
	.title{
		padding: 10px 15px;
		background-color: $light-grey;
		cursor: pointer;
		svg{
			font-size: 20px;
			transition: 0.1s ease-in-out;
			&.open{
				color: $honda-red;
			}
		}
	}
	.contentHolder{
		overflow: hidden;
	}
	margin-bottom: 8px;
}
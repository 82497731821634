.productTimelines {
	margin-bottom: 55px;
	.productTimelinesHolder {
		width: 100%;
		height: 85vh;
		overflow: hidden;
		position: relative;
		.timeline {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 50%;
			left: 0;
			transform: translateY(-50%);
			
			.lazyLoad {
				width: 100%;
				height: 100%;
				text-align: center;
			}
			.contentHolder {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: rgba($color: #000000, $alpha: 0.6);
				overflow-y: auto;
				overflow-x: hidden;
				@media screen and (max-width: "760px"){
					background-color: rgba($color: #000000, $alpha: 0.3);
				}
				/* Hide scrollbar for Chrome, Safari and Opera */
				&::-webkit-scrollbar{
					display: none;
				}
				/* Hide scrollbar for IE, Edge and Firefox */
				&{
					-ms-overflow-style: none;  /* IE and Edge */
					scrollbar-width: none;  /* Firefox */
				}
				.timelineScroll{
					padding: 5% 0;

				}
				.content {
					width: 70%;
					margin: 0 auto;
					color: white;
					margin-top: 30px;
					.copy {
						width: 75%;
						@media screen and (min-width: "1200px") {
							font-size: 1.20rem;
						}
						@media screen and (max-width: "1199px") {
							font-size: 1.1rem;
						}
					}
				}
			}
			&.active{
				z-index: 5;
			}
			&.inactive{
				z-index: 1;
			}
		}

		.timelineSteps {
			position: absolute;
			margin: 0;
			top: 0;
			height: 100%;
			left: 5%;
			display: flex;
			align-content: center;
			align-items: center;
			counter-reset: stepCount;
			display: flex;
			justify-content: space-around;
			z-index: 99;
			flex-flow: column;
			font-family: $montserrat;
			font-weight: 700;
			.item {
				height: 100%;
				text-align: center;
				line-height: 2em;
				margin: 0 1em;
				display: inline-block;
				color: white;
				position: relative;
				width: 4em;
				cursor: pointer;
				.icon {
					position: absolute;
					width: 4em;
					height: 4em;
					line-height: 4em;
					top: 50%;
					transform: translateY(-50%);
					border: 1px solid white;
					border-radius: 2em;
					svg {
						width: 50%;
						height: 100%;
					}
					&:after {
						width: 4.6em;
						height: 4.6em;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						border: 1.5px solid white;
						border-radius: 50%;
						position: absolute;
						content: " ";
						opacity: 0;
						transition: opacity 0.3s ease-in-out;
					}
				}
				&:before {
					content: "";
					position: absolute;
					top: 0;
					left: 50%;
					width: 1px;
					height: calc(50% - 2em);
					background: white;
					z-index: -1;
					transform: translateX(-50%);
				}
				&:after {
					content: "";
					position: absolute;
					bottom: 0;
					left: 50%;
					width: 1px;
					height: calc(50% - 2em);
					background: white;
					z-index: -1;
					transform: translateX(-50%);
				}
				&.active {
					.icon {
						&:after {
							opacity: 1;
							box-shadow: 0 0 0 rgba(202, 202, 202, 0.8);
							animation: pulse 1.5s infinite;
						}
					}
				}
			}
		}
	}
}

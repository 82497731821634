.bookATestDrive__container {
	@extend %container;
	width: 80% !important;

	.preferredDealerField div > h5 {
		margin-bottom: 16px;
	}

	.row {
		margin: 16px 0 !important;
	}
}
.bookATestDrive {
	position: relative; //for select button to pop back ontop of footer
	.chooseProducts {
		overflow: hidden;
		position: relative;
		.products {
			margin-bottom: 80px;
			.product {
				position: relative;
				cursor: pointer;
				.children {
					position: absolute;
					top: 20%;
					left: 2.5%;
					width: 95%;
					overflow: hidden;
					z-index: 99;
					.childrenHolder {
						background-color: rgba($color: #ffffff, $alpha: 0.9);
						border: 3px solid $light-grey;
						.selectable {
							padding: 10px 15px;
							border-bottom: 3px solid $light-grey;
							.block {
								width: 20px;
								height: 20px;
								border: 1px solid $dark-grey;
								display: inline-block;
								margin-right: 15px;
								transition: background-color 0.3s ease-in-out;
							}
							&:last-child {
								border-bottom: 0;
							}
							&:hover {
								.block {
									background-color: $honda-red;
								}
							}
							&.active {
								.block {
									background-color: $honda-red;
								}
							}
						}
					}
					&.loaded {
						height: 0 !important;
					}
				}
				.offClick {
					position: fixed;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
				}
				.productCard {
					&.active {
						.productTitle {
							color: $honda-red;
						}
					}
				}
			}
		}

		$rightPosition: 250px;

		.confirmSelection {
			bottom: 10px;
			z-index: 99;
			transition: opacity 0.5s linear;
			right: 0;
			&.fixed {
				position: fixed;
				margin-right: $rightPosition;
			}
			&.absolute {
				position: absolute;
				margin-right: calc(#{$rightPosition} - 8.8%);
			}
		}
	}
	.form {
		&.close {
			overflow: hidden;
		}
		h5 {
			margin-top: 0;
		}
		.productCard {
			border: 1px solid $light-grey;
			padding: 0 15px;
			border-bottom: 0;
			padding-bottom: 15px;
			&.active {
				.productTitle {
					color: $honda-red;
				}
			}
			&:last-child {
				border-bottom: 1px solid $light-grey;
			}
		}
		.addMoreProducts {
			border: 1px solid $light-grey;
			padding: 15px;
			text-align: center;
			border-top: 0;
			cursor: pointer;
			transition: color 0.1s ease-in-out;
			&:hover {
				color: $honda-red;
			}
		}
	}
	.thankYou {
		overflow: hidden;
	}
}

.bookATestDrivePopUp {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
	&.active {
		z-index: 99999;
	}
	.shade {
		position: absolute;
		top: 0;
		left: 0;
		background-color: rgba($color: $dark-grey, $alpha: 0.7);
		width: 100%;
		height: 100%;
	}
	.popUp {
		position: absolute;
		background-color: white;
		width: 80%;
		height: 80%;
		max-width: 1280px;
		left: 50%;
		top: 50%;
		overflow-y: auto;
		padding: 35px;
		.close {
			// padding: 20px 0;
			cursor: pointer;
			transition: 0.3s ease-in;
			&:hover {
				transform: rotate(90deg);
			}
		}
		.formLoader {
			height: 100%;
			position: relative;
			span {
				font-size: 80px;
				margin: 0;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}
		.customContainer {
			width: 100%;
			.intro {
				margin-bottom: 35px;
				h2.left {
					margin: 0;
				}
			}
			.productSelect {
				display: flex;
				flex-wrap: wrap;
				margin-bottom: 55px;
				.productHolder {
					width: 100%;
					border: 2px solid $light-grey;
					.productSelectTitle {
						background-color: $dark-grey;
						width: 100%;
						min-height: 100% !important;
						border: 2px solid rgba($color: $dark-grey, $alpha: 0.7);
						h5 {
							margin: 0;
							border: none;
							color: white !important;
							padding: 10px 0 10px 10px;
						}
					}
					.img {
						display: block;
						width: 100%;
						text-align: center;
						img {
							width: 100%;
							height: auto;
							max-width: 400px;
						}
					}
					.models {
						width: 100%;
						.model {
							border-top: 2px solid $light-grey;
							padding: 15px;
							text-transform: uppercase;
							font-weight: 700;
							text-decoration: underline;
							text-decoration-color: white;
							transition: 0.3s ease-in;
							cursor: pointer;
							&:hover,
							&.active {
								text-decoration-color: $honda-red;
								background-color: #f3f3f3;
							}
						}
					}
				}
				.childrenHolder {
					display: flex;
					.special {
						width: 100%;
						border: 2px solid $light-grey;
						padding: 15px;
						.title {
							color: $dark-grey;
							h2 {
								margin: 0;
							}
						}
						.from {
							margin-top: 15px;
							font-size: 20px;
							color: $honda-red;
							font-weight: 700;
							font-family: $montserrat;
						}
						.description {
							margin-top: 15px;
							color: $dark-grey;
							p {
								margin: 0;
								strong {
									font-weight: 700;
								}
							}
						}
					}
				}
			}
		}
	}
}

.bookATestDrive__component {
	.preferredDealerField .inputWrapper .searchLocationHolder {
		padding: 0 0.75rem 2rem 0;
	}
	.preferredDealerField {
		div > h5 {
			padding: 0;
		}
	}

	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;

	.form__component {
		background-color: white;
		width: 100%;
		height: 80%;
		padding: 35px;

		.customContainer {
			width: 100%;
			.intro {
				margin-bottom: 35px;
				h2.left {
					margin: 0;
				}
			}
			.productSelect {
				display: flex;
				flex-wrap: wrap;
				margin-bottom: 55px;
				.productHolder {
					width: 100%;
					border: 2px solid $light-grey;
					.productSelectTitle {
						background-color: $dark-grey;
						width: 100%;
						min-height: 100% !important;
						border: 2px solid rgba($color: $dark-grey, $alpha: 0.7);
						h5 {
							margin: 0;
							border: none;
							color: white !important;
							padding: 10px 0 10px 10px;
						}
					}
					.img {
						display: block;
						width: 100%;
						text-align: center;
						img {
							width: 100%;
							height: auto;
							max-width: 400px;
						}
					}
					.models {
						width: 100%;
						.model {
							border-top: 2px solid $light-grey;
							padding: 15px;
							text-transform: uppercase;
							font-weight: 700;
							text-decoration: underline;
							text-decoration-color: white;
							transition: 0.3s ease-in;
							cursor: pointer;
							&:hover,
							&.active {
								text-decoration-color: $honda-red;
								background-color: #f3f3f3;
							}
						}
					}
				}
				.childrenHolder {
					display: flex;
					.special {
						width: 100%;
						border: 2px solid $light-grey;
						padding: 15px;
						.title {
							color: $dark-grey;
							h2 {
								margin: 0;
							}
						}
						.from {
							margin-top: 15px;
							font-size: 20px;
							color: $honda-red;
							font-weight: 700;
							font-family: $montserrat;
						}
						.description {
							margin-top: 15px;
							color: $dark-grey;
							p {
								margin: 0;
								strong {
									font-weight: 700;
								}
							}
						}
					}
				}
			}
		}
	}
}

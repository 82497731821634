
.carousel-component{
    position: relative;
    z-index: 99;
    margin: 0;
    width: 100%;

    &[data-device="mobile"]{
        margin-bottom: 16px;
    }

    &[data-device="mobile"]{
        .carousel-component__window {
            min-height: 375px;
        }
    }

    .carousel-component__window{
        position: relative;
        display: block;
        overflow: hidden;
        margin: 0 auto;
        width: 100%;

        &:after{
            display: block;
            content: "";
            width: 100%;
        }

        div.carousel-component__window-item{
            position: absolute;
            top: 0;
            display: block;
            width: 100%;            
        }
    }

    
    
    .carousel-component__nav{
        bottom: 0;
        left: 0;
        right: 0;

        .chevron{
            color: #fff;
            background: none;
            border: none;
            outline: none;
            position: absolute;
            z-index: 1000;
            padding: 25px 15px;
            transition: all 0.5s ease;
            transform: translateY(-50%) !important;            
            cursor: pointer;

            &.previous {
                left: 1%;
            }

            &.next {
                right: 1%;
            }

            &:hover{
                background-color: rgba(51,51,51,.7);
                transition: all 0.5s ease;
            }

            .carousel__nav-item{
                color: white;
            }
        }
    }

    .carousel-component__bullets{
        @extend .rowSlider;
        height: 100%;
        .bullets{
            padding: 0;
            bottom: 0 !important;
            
            &[data-bullets-position="left"]{
                text-align: left;
            }
            &[data-bullets-position="center"]{
                text-align: center;
            }
            &[data-bullets-position="right"]{
                text-align: right;
            }
        }
    }

    &[data-device="desktop"]{
        .carousel-component__bullets{
            height: 54px;
            margin-top: -54px;
            width: 100%;
            z-index: 1000;
            .bullets{
                width: 90%;
                left: 5% !important;
                right: 5% !important;
                padding: 5px;
            }
        }
    }
    
    &[data-device="tablet"]{
        .carousel-component__bullets{
            padding-top: 40px;
            position: relative;
            height: 34px;
            background-color: rgba(51,51,51,.2);
            .bullets{
                // background-color: yellowgreen;
                width: 95%;
                left: 2.5% !important;
                right: 2.5% !important;
                padding: 0;
            }
        }
    }

    &[data-device="mobile"]{
        .carousel-component__bullets{
            padding-top: 40px;
            position: relative;
            height: 34px;
            background-color: rgba(51,51,51,.2);
            .bullets{
                width: 95%;
                left: 2% !important;
                right: 2% !important;
                padding: 0;
            }
        }
    }
}
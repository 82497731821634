.campaignContainer{
    background-color: transparent;
    .featured-product{
        padding: 0 7%;
        // max-height: 600px;

		.product-card-shopping-tools{
			background-color: #f4f4f4 !important;
			padding: 16px 0;
			margin-bottom: 64px;
		}
        .campaignProductWrapper{
            // padding: 10px;
            .banner{
                .productBannerCopyHolder{
                    position: relative;
                    .productBannerCopy{
                        @media only screen and (max-width: "699px") {
                            min-height: 100%;
                            padding: 0;
                            position: relative;
                            left: 0;
                            margin-top: 145px;

                            .buttons {
                             .button{
                                 display: block !important;
                                a{
                                    margin: 10px 0 !important;
                                    width: 100% !important;
                                }
                             }
                            }
                        }

                        .buttons {
                            position: absolute;
                            display: block;
                            width: 100%;
                            bottom: 88px;
                            @media only screen and (min-width: "1001px") and (max-width: "1200px") {
                                bottom: 40px;
                            }
                            @media only screen and (min-width: "700px") and (max-width: "1000px") {
                                bottom: 40px;
                                &.left{
                                    left: 7%;
                                }
                                &.right{
                                    right: 7%;
                                }
                            }
                            @media only screen and (max-width: "699px") {
                                bottom: 10px;
                                text-align: center !important;
                            }
                            &.right{
                                text-align: end;
                            }
                            .button{
                                display: inline-block;
                                text-align: center;
                                a{
                                    text-align: center;
                                    width: auto;
                                    @media only screen and (max-width: "699px") {
                                        width: 200px;
                                        margin: 10px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .tc-copy{
        padding: 20px 7% 30px;
        position: relative;
        h4{
            color: #ffffff;
            font-weight: 300;
            margin: 0;
            line-height: 35px;
            @media only screen and (max-width: "1000px") {
                font-size: 22px;
                text-align: center;
            }
        }
    }
}

.customContainer{
    &[data-color="white"]{
        color: white;
    }
}
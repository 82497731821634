.campaignContainer{
    background-color: #1c1e21;
    section {
        background-color: #284877;
        padding: 20px 7% 20px;
        position: relative; 
        z-Index: 0;
        .bottom-fade{
            position: absolute; 
            height: 100%; 
            width: 100%;
            background: linear-gradient(180deg, transparent, rgba(28,30,33, 0.61), rgba(28,30,33, 1));
            z-Index: -1;
            bottom: 0;
            right: 0;
            left: 0;
        }
        .promo-copy{
            h4{
                color: #ffffff;
                font-weight: 300;
                margin: 0;
                line-height: 35px;
                @media only screen and (max-width: "1000px") {
                    font-size: 22px;
                }
                @media only screen and (max-width: "600px") {
                    font-size: 17px;
                }
            }
        }
        .icon-benefits{
            margin: 70px 0;
            @media only screen and (max-width: "560px") {
                margin: 30px 0;
            }
            .icon-title{
                width: 20%; 
                height: 60px; 
                float: left;
                @media only screen and (max-width: "560px") {
                    width: 50%;
                    height: 130px;
                }
                .icon-title-wrapper{
                    width: 100%;
                    height: 60px;
                    @media only screen and (max-width: "894px") {
                        text-align: center;
                    }
                    .wrapper{
                        width: auto; 
                        display: inline-block;
                        .icon-wrapper{
                            display: inline-block;
                            width: 60px;
                            height: 60px;
                            border: 3px solid #ffffff;
                            border-radius: 5px;
                            background-color: #ffffff;
                            color: #284877;
                            .icon{
                                position: relative;
                                color: #284877;
                                width: 100%;
                                height: 100%;
                                @media only screen and (max-width: "894px") {
                                    text-align: initial;
                                }
                            }
                        }
                    }
                    .title-wrapper{
                        display: inline-block;
                        width: 61%;
                        height: 60px;
                        vertical-align: top;
                        @media only screen and (max-width: "894px") {
                            width: 100%;
                        }
                        .title{
                            padding-top: 7px;
                            color: #ffffff;
                            width: 100%;
                            height: 100%;
                            padding-left: 15px;

                            @media only screen and (max-width: "894px") {
                                padding-left: 0;
                                text-align: center;
                            }
                        }
                    }
                }
                
            }
        }
    }
}
.tabs-container{
    .cbTitle{
        text-align: center;
        color: #fff;
        padding-top: 10px;
    }
    .tabs-wrapper{
        margin: 30px 0;
        text-align: center;
        @media screen and (max-width: "1446px") {
            white-space: nowrap;
            width: 100%;
            white-space: nowrap;
            overflow-x: auto;
            &::-webkit-scrollbar-track{
                opacity: 0;
            }
        }
        li {
            padding: 10px 10px 15px;
            display: inline-block;
            a {
                color: #fff;
                font-size: 16px;
                text-transform: uppercase;
                font-family: "Montserrat";
                font-weight: 800;
                letter-spacing: 0.1rem;
                transition: all 0.5s ease;
                @media screen and (min-width: "700px") {
                    font-size: 1.15rem;
                }
                -webkit-touch-callout: none; /* iOS Safari */
                -webkit-user-select: none; /* Safari */
                -khtml-user-select: none; /* Konqueror HTML */
                -moz-user-select: none; /* Old versions of Firefox */
                -ms-user-select: none; /* Internet Explorer/Edge */
                user-select: none; /* Non-prefixed version, currently
                                            supported by Chrome, Edge, Opera and Firefox */
                cursor: pointer;
            }
            &.active {
                a {
                    color: $honda-red !important;
                }
            }
        }
    }
    .mobile{
        padding: 20px 0;
        position: relative;
        .col {
            position: relative;
            width: 100%;
            button {
                width: 100%;
                height: 50px;
                background-color: $honda-red;
                border-radius: 2px;
                font-size: 16px;
                color: #ffffff;
                text-align: center;
                border: none !important;
                padding: 13px 40px;
                text-decoration: none;
                display: inline-block;
                white-space: nowrap;
                cursor: pointer;
            }
            .dropdown{
                margin: 0 auto;
                position: absolute;	
                width: 96%;			
                background-color: rgba(
                    $color: $primary_navigation_backgroundColor,
                    $alpha: 0.8
                );
                backdrop-filter: blur(2px);
                z-index: 999;
                overflow: hidden; 
                li {
                    position: relative;
                    vertical-align: middle;
                    width: 100%;
                    transition: 0.1s ease-in;
                    white-space: nowrap;
                    padding: 15px 0;
                    overflow: hidden;
                    &.active{
                        a {
                            color: $honda-red;
                        }
                    }
                    a {
                        position: relative;
                        text-align: center;
                        transition: 0.1s ease-in;
                        color: #fff;
                        font-size: 18px;
                        padding: 5px 0;
                        display: block;
                        cursor: pointer;
                    }
                    
                    &::after{
                        content: '';
                        position: absolute;
                        display: block;
                        background-color: white;
                        bottom: 0;
                        height: 1px;
                        width: 100%;                        
                    }
                }
            }
        }
    }
    
}
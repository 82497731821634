.loadingHolder{
	background-color: $dark-grey;
	position: fixed !important;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 999999;
	.loading{
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateY(-50%) translateX(-50%);
		color: white;
	}
}

body{
	&.loading{
		overflow: hidden;
	}
}
@media(max-width: 1065) {
	.categoryBuilderHolder {
        .productMobileSlider {
            .productTitle {
                h2 {
                    font-size: 35px;
                }
            }
        }
    }
}

@media(max-width: 1000px) {
	h1{
		font-size: 35px;
	}
    //Brand Logo 
    nav{ 
        height: 60px !important; 
    }
    .nav-wrapper  {
        padding: 0 4.5% !important;
        &.dark {
            background-color: rgba(51, 51, 51, 0.9) !important;
            backdrop-filter: blur(4px);
        }
        .brand-logo {
            img {
                width: 140px;
            }
            &.left {
                left: 1.5rem !important;
            }
        }
        .sidenav-trigger {
            margin: 0 5px;
            i {
                font-size: 30px !important;
            }
		}
    }

    // Headers/Nav
    .navbar-fixed.productNav {
        .valign-wrapper {
            display: unset;
            &.top_navbar_list {
                a.navItem{
                    padding-left: 10px;
                }
                &.navDropdown {
                    padding-left: 10px;
                }
            }
        }
        .navDropdown {
            li.notActive {
                display: none;
            }
        }
        .navBlockItem {
            padding-left: 10px;
        }
    }

    // General Header
    .generalNav {
        height: 0;
        .nav-extended {
            height: 60px !important;
            .nav-wrapper {
                display: none;
                
                .sidenav-trigger {
                    margin: 0;
                    i {
                        line-height: 64px;
                        height: 64px;
                    }
                }
            }
            .nav-content {
                padding: 0 4.5% !important;
                &.dark {
                    background-color: rgba(51, 51, 51, 0.9) !important;
                    backdrop-filter: blur(4px);
                }
                .brand-logo {
                    left: 1.5rem;
                    transform: unset;
                    img {
                        width: 120px;
                    }
                }
            }
        }
        .openSideNavMobile {
            .openSideNav {                
                .bottomNavList {
                    display: flex;
					flex-flow: column-reverse;
				 }
            } 
        }
    } 
    // Default Banner
    .banner {
        .productBannerCopyHolder {
            position: unset;
            padding-top: 2rem;
            .productBannerCopy {
                position: unset;
                transform: none;
                width: 100%;
                .copy {
                    padding: 0 20px !important;
                    width: 100%;
                    h1 {
                        color: $black;
                        font-size: 30px;
                        line-height: 48px;
                    }
                    h6 {
                        color: $black;
                    }
                }
                .buttons {
					margin-bottom: 20px;
                    a {
                        width: calc(50% - 10px);
                        margin: 5px;
                        &:hover {
                            cursor: pointer;
                        }
                        &.bbwhite{
                            border: black 1px solid !important;
                            // background-color: white !important;
                            color: black !important;
                        }
                        &.bblightGrey{
                        border: rgb(131, 131, 131) 1px solid !important;
                    }
					&.bbdarkGrey{
                        border: rgb(114, 114, 114) 1px solid !important;
                    }
					&.bbsolidWhite{
                        border: black 1px solid !important;
                            color: black !important;
                    }
                    
                    }
                }
                // .countdownTimer {
                //     top: 35%;
                // }
            }
        }
        .imageHolder {
            .overlay {
                display: none;
            }
		}
		.arrow{
			display: none;
		}
	}
    .smallBanner {
        .productBannerCopyHolder {
            .productBannerCopy {
                .copy {
                    padding: 0 20px !important;
                    width: 100%;
                    h1 {
                        color: white;
                        font-size: 30px;
                        line-height: 48px;
                    }
                    h6 {
                        color: $black;
                    }
                }
            }
        }
    }
    // Cars Homepage
    .categoryBuilderHolder {

        // FeatureProductSlider
        .featureProductSlider {
            margin: 0;
            &.mt32px {
                margin-top: 0 !important;
            }
        }

        // Products and Products Slider for Desktop
        .productDesktop {
            display: none;
        }

        // Product Mobile Slider
        .productMobileSlider {
            .rowSlider {
                .navItem {
                    transform: translateY(-100%);
                    svg {
                        width: 15px;
                        path {
                            color: $black;
                        }
                    }
                    &:hover {
                        background: none;
                    }
                }
            } 
            .productDescription {
                text-align: center;
            }
            .productTitle {
				left: 10%;
                h2 {
                    font-size: 35px;
                }
            }
            .productButton {
				text-align: center;
                a {
                    width: auto;
                }
            }
        }
    }
    // Global Shoping Tools
    .shoppingTools {
        .col {
            .toolHolder {
                .tool {
                    width: 125px !important;
                    height: 125px !important;
                    svg {
                        width: 45px;
                    }
                }
            }
        }
    }
    // Open/Close Sidenav
    .openSideNavMobile {
        height: 100vh;
        position: fixed;
        width: 100%;
        background-color:#333333b5;
        top:60px;
        .sidenav {
            background: #fff;
            background-color: rgba(
							$color: $primary_navigation_backgroundColor,
							$alpha: 0.7
						);
            height: 100%;
            width: 300px;
            transition-duration:.3s;
            top:60px;
            li {
                border-bottom: solid 1px #fff;
                font-size: 18px;
                color: #fff;
                
                a {
                    height: auto;
                    color: #fff;
                    font-size: 18px;
                    padding: 0;
                    &.active {
                        color: #f51638;
                        background-color: rgba(
							$color: #000000,
							$alpha: 0.5
						);
                    }
                }
            }
    
        }
        .openSideNav {
            transform: translateX(0%);
            opacity: 1;
            padding-left: 15px;
            padding-right: 15px;
            backdrop-filter: blur(4px);

            svg {
                font-size: 20px;
                position: relative;
                // left: 10px;
                // top: 4px;
                color: #fff;
                // margin: 0 12px;
            }
        }
        .closeSideNav {
            transform: translateX(-100%);
            opacity: 0;
        }
    }
    // Hide Large Footer for Desktop
    .largeFooterDesktop {
        display: none;
    }
    // Show large footer for Mobile
    .largeFooterMobile {
        width: 100%;
        .col {
            padding: 0;
            .updateHeading {
                padding: 5px 20px;
                h2 {
                    font-size: 24px;
                    letter-spacing: 2px;
                }
            }
            .accordion {
                .title {
                    background: #212121;
                    border-top: 1px solid $light-grey;
                }
                &:nth-last-child(1) {
                    .title {
                        border-bottom: 1px solid $light-grey;
                    }
                }
                .contentHolder {
                    padding: 0px 10px;
                }
            }
                
            .updateButton {
                padding-bottom: 15px;
                margin: 0 20px;
                a {
                    width: 100%;
                }
            }
        }
    }
    //car list product
    .listCarProduct{
        .productCard {
            .productHeading {
                h2 {
                    font-size: 22px;
                }
            }
            .productDescription {
                p {
                    margin-top: 5px;
                }
            }
        }
    }
    // Finda a Dealer
    .findADealer {
        .banner {
            .hiddenH1 {
                text-align: center;
                visibility:visible;
                font-size: 28px;
                height: unset;
                margin-bottom: 25px;
            }
            .overlay {
                position: absolute;
                width: 100%;
                height: 100%;
                z-index: 1;
                background: #0000005c;
            }
            img {
                height: 100%;
                width: unset;
            }
            .location {
                z-index: 2;
                width: 95%;
                h2 {
                    font-size: 28px;
                    margin: 25px 0;
                }
            }
        }
    }
    // Show Location Visible:: Find a Dealerpage
    .showLocationVisible {
        .banner {
            .location {
                button.currentLocation {
                    display: none;
                }
            }
        }
        .results {
            .row  {
                display: flex;
                flex-direction: column-reverse;
                .mapHolder {
                    width: 100%;
                }
                .dealersHolder {
                    width: 100%;
                }
            }
        }
    }
    // bookATestDrive
    .bookATestDrive {
        width: 91%;
		.chooseProducts {
            $rightPosition: 90px;
		.confirmSelection{
			bottom: 10px;
			z-index: 99;
			transition: opacity 0.5s linear;
			&.fixed{
				position: fixed;
                // right: calc(#{$rightPosition} - 8.8%);
                margin-right: 0;
                right: 38px;
			}
			&.absolute{
				position: absolute;
                // right: calc(#{$rightPosition} - 8.8%);
                margin-right: 0;
                // right: 0;
			}
		}
	}

        // Open Form
        .form {
            &.open {
                .addMoreProducts {
                    color: $honda-red;
                }
                .productCard {
                    margin-top: 10px;
                    .productWrapper {
                        .productImage {
                            top: 35px;
                        }
                    } 
                } 
                .carDetailsHeading {
                    margin-top: 10px;
                    margin-bottom: 15px;
                }
                button {
                    &.bookATestDrive {
                        width: 100%;
                    }

                }
                .row {
                    &.preferredDealerField {
                        .inputWrapper {
                            .searchLocationHolder {
                                width: 73% !important;
                            }
                            .currentLocationHolder {
                                width: 27% !important;
                                button {
                                    text-align: center;
                                    span {
                                        display: none;
                                    }
                                    svg {
                                        float: unset;
                                    }
                                }
                            }
                        }
                    }
                } 
            }
        }
    }
    // Book a Servicepage
    .bookAService, .requestAPart { 
        &.mt64px {
            margin-top: 0 !important;
        }
        .formHolder {
            h3 {
                font-size: 28px;
            }
            h4 {
                font-size: 22px;
                line-height: 30px;
            }
            button {
                &.bookAService,  &.requestAPart {
                    width: 100% !important;
                }
            }
            .introDescription {
                p {
                    font-size: 16px;
                }
            }
            .row {
                &.preferredDealerField {
                    .inputWrapper {
                        .searchLocationHolder {
                            width: 73% !important;
                        }
                        .currentLocationHolder {
                            width: 27% !important;
                            button {
                                text-align: center;
                                &.currentLocation {
                                    span {
                                        display: none;
                                    }
                                    svg {
                                        float: unset;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    // Homepage
    .homePage {
        .bannerCarousel {
            .rowSlider {
                .slideWrapper {
                    .slideHolder {
                        height: auto !important;
                    }
				}
				.bullets {
					left: 25px;
					bottom: -10%;
					padding: 0;
					span {
						width: 25px;
						height: 25px;
					}
				}
				.contain{
					.navItem{
						top: 25%;
					}
				}
			}
            .carouselSlider {
                .slideWrapper {
                    .slideHolder {
                        .slide {
                            .banner {
                                .productBanner {
                                    .productBannerCopy {
                                        .buttons {
                                            margin-top: 30px;
                                        }
                                    }
                                }
                            }
                        }
                    }
				}
				.exploreClick{
					padding: 15px 30px;
				}
            }
            .thumbnails {
                .carouselThumbnail {
                    width: 33.33%;
                }
            }
        }
    }
     // List Special Offers
     .listSpecialOffers {
         .specialCard {
             margin: 20px 0;
            .specialButton {
                a {
                    margin: 10px 0;
                    display: block;
                }
            }
            .specialDescription {
                p {
                    margin: 10px 0;
                }
            }
        }
	}
	.messages{
		.col{
			padding: 2px 0;
		}
	}
	.sideNavHolder {
		.sideNavItem {
			margin-bottom: 10px;
			.content{
				height: 50px;
				max-height: 50px;
				p{
					padding: 0  
				}
				.title{
					font-size: 25px;
				}
				
			}
			.icon{
				width: 50px;
				height: 50px;
				font-size: 1.6em;
			}
			&:hover{
				.icon{
					font-size: 2em;
				}
			}
		}
	}
	.preferredDealerField {
		.inputWrapper {
			.searchLocationHolder {
				.searchLocation{
					padding: 20px;
    				font-size: 18px;
				}
			}
		}
	}
	.filterNavHolder{
		.navBuilder{
			white-space: nowrap;
			overflow-x: auto;
			.navTitle{
				margin: 0 16px;
			}
			.navBuilderItem {
                padding: 15px 0 10px;
				a{
					padding: 0 16px;
				}
			}
		}
	}

	.campaignBuilderHolder {
		.campaignBuilder {
			.cbTitle{
				font-size: 25px;
				margin: 18px 0;
			}
			.copyWithImage {
				.copyHolder {
					.cbTitle{
						font-size: 25px;
						margin: 18px 0;
					}
				}
			}
		}
    }
    
    .customContainer{
        .mt64px{
            margin-top: 0 !important;
        }
     }

	.campaignBanner{
		&.fc-white{
			.productBannerCopyHolder{
				.productBannerCopy {
					.copy {
						h1, h6{
							color: white !important;
						}
					}
				}
			}
		}
    }
    //errorPage
    .errorBody{
        min-height: 280px;
        & > div {
            padding: 10px 0px;
        }
    }
}

@media(max-width: 1000px) and (min-width: 601px) {    
    // productBannerHolder
    .banner {
        .productBannerCopyHolder {
            .productBannerCopy {
                padding: 0 25px;
            }
        }
	}	
	
    // List Car Homepage
    .listCarProduct {
        .row {
            display: flex;
            flex-flow: wrap;
            .col:nth-child(2n+1) {
                .productCard {
                    &:before {
                        content: none !important;
                    }
                }
            }
            .col {
                margin-left: unset;
            }
        }
        .productCard {
            position: relative;
            &:before {
                content: '';
                position: absolute;
                top: 0;
                height: 100%;
                width: 2px;
                background-color: $light-grey;
    
            }
            .productImage {
                padding: 0 30px;
            }
            .productHeading {
                h2 {
                    font-size: 15px;
                }
			}
			.productDescription{
				p{
					font-size: 13px;
				}
			}
        }
    }
    .categoryBuilderHolder {

        // messages 
        .messages {
           margin-left: 20px;
           margin-right: 20px;
           margin-bottom: 30px;
       }

       // Feature Card
       .featuredCard {
           margin: 15px 20px;
           .col {
               padding: 5px 0;
               .linkCard {
                   .imageHolder {
                       .content {
                           .title {
                               left: 15px;
                           }
                       }
                   } 
               }
           }
       }
    }
    
    // Homepage
    .homePage {
        .shoppingTools {
            .toolHolder {
                .tool {
                    .hover {
                        top: -28% !important;
                        left: 28% !important;
                    }
                } 

            } 
        }
        .bannerCarousel {
            .carouselSlider {
                .exploreClick{
                    position: relative;
				}
            }
            .rowSlider {
				position: static;
				.bullets {
                    bottom: -12%;
                }
            }
            .thumbnails{
                margin-top: 64px;
            }
        }
    }

    // List Special Offers
    .listSpecialOffers {
        width: 91%;
    }
}

@media(max-width: 680px) and (min-width: 601px) {
	.productCard {
		.productWrapper {
			.productTitle{
				h2{
					font-size: 28px;
				}
			}
		}
	}
}

@media(max-width: 800px){
	h1{
		font-size: 35px;
	}
}
.financingCardsHolder{
	.financingCard{
		.image{
			position: relative;
			.overlayTitle{
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translateX(-50%) translateY(-50%);
				color: white;
				font-family: $montserrat;
				font-size: 18px;
			}
		}
		.content{
			padding: 15px;
		}
		.readMore{
			a{
				color: $honda-red;
				text-transform: uppercase;
			}
		}
	}
}
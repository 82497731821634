.linkCard{
	.imageHolder{
		width: 100%;
		position: relative;
		display: grid;
		img{
			width: 100%;
			height: auto;
			// min-height: 192px;
		}
		.content{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			.title{
				font-size: 32px;
				line-height: 20px;
				margin: 0;
				margin-top: 16px;
				margin-bottom: 16px;
				display: block;
				font-size: 28px;
				font-weight: 900;
				color: white;
				position: absolute;
				bottom: 5px;
				left: 32px;
				z-index: 99;
				.line{
					width: 64px;
					height: 5px;
					background-color: #f51638;
					margin-bottom: 15px;;
				}

			}
			&:after{
				content: "";
				position: absolute;
				left: 0;
				bottom: 0;
				width: 100%;
				height: 40%;
				background: linear-gradient(to top, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.54) 43%, rgba(0, 0, 0, 0.23) 74%, rgba(0, 0, 0, 0) 100%);
				background-size: cover;
			}
		}
	}
}

.productCard{
	.productWrapper{
		position: relative;
		.productTitle{
			position: absolute;
			z-index: 3;
			color: $light-grey;
			transition: color 0.3s ease-in-out;
		}
		.productImage{
			position: relative;
			z-index: 5;
			text-align: center;
		}
		&:hover{
			.productTitle{
				color: $honda-red;
			}
		}
	}
	.productDescription{
		color: $dark-grey;
	}
}

.specialCard{
	border: 1px solid $light-grey;
	width: 100%;
	.specialWrapper{
		// position: relative;
		// padding: 0 15px 15px;
		border-bottom: 1px solid $light-grey;
		// height: 260px;
		.specialTitle{
			// position: absolute;
			top: 15px;
			// height: 113px;
			// z-index: 3;
			color: $light-grey;
			transition: color 0.3s ease-in-out;

			h2{
				margin: 0;
			}

			&.above{
				position: static;
				h2{
					margin: 15px 0;
				}
			}
		}
		.specialImage{
			// position: relative;
			// z-index: 5;
			text-align: center;
			// height: 150px;
			.lazyLoad{
				height: 100%;
				img {
					object-fit: contain;
					width: 100%;
					height: 100%;
				}
			}
			&.above{
				margin-bottom: 15px;
			}
		}
	}
	.specialFrom{
		padding: 0 15px;
		padding-top: 15px;
		font-size: 20px;
		color: $honda-red;
		font-weight: 700;
		font-family: $montserrat;
	}
	.specialDescription{
		color: $dark-grey;
		padding: 15px 15px;
		// height: 150px;
		// overflow: hidden;
		p{
			margin: 0;
			strong{
				font-weight: 700;
			}
		}
	}
	.specialButton{
		padding: 15px;
		a {
			margin-top: 5px;
		}
	}
}

.hmcHolder{
	border: 1px solid $light-grey;
	margin-bottom: 20px;
	.imgHolder{
		background-color: $white-grey;
		border-bottom: 1px solid $light-grey;
	}
	.contentHolder{
		padding: 10px;
		h4{
			margin-top: 0;
			color: $dark-grey;
		}
		.desc{
			color: $dark-grey;
		}
	}
}
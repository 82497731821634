.selectWrapper{
	margin-bottom: 15px;
	h5 {
		margin-top: 0;
		margin-bottom: 5px;
		font-size: 15px;
	}
	.hint {
		margin-bottom: 5px;
		color: $light-grey;
		font-size: 12px;
	}
	.selectHolder {
		position: relative;
		& > .title {
			position: relative;
			cursor: pointer;
			background-color: transparent;
			border: none;
			outline: none;
			width: 100%;
			font-size: 16px;
			margin: 0;
			padding: 0;
			display: block;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
			z-index: 1;
			width: 100%;
			padding: 13.5px 0;
			padding-right: 20px;
			border: 2px solid $light-grey;
			background-color: transparent;
			margin-top: 5px;
			text-indent: 10px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			.caret{
				margin: 5px 10px;
				position: absolute;
				right: 0;
			}
		}
		& > .selectOptionsHolder {
			background-color: #ffffff;
			border: none !important;
			z-index: 200;
			overflow: hidden;
			overflow-y: auto;
			position: absolute;
			z-index: 5;
			top: 0;
			left: 0;
			width: 100%;
			// max-height: 500px;
			max-height: 300px; //else will goes under footer
			-webkit-box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
			box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
			.item {
				clear: both;
				cursor: pointer;
				min-height: 50px;
				line-height: 1.5rem;
				width: 100%;
				text-align: left;
				color: $dark-grey;
				line-height: 22px;
				padding: 14px 16px;
				font-size: 16px;
				&:hover{
					background-color: #eee;
				}
				&:focus {
					background-color: inherit;
				}
				.plus {
					display: inherit;
					float: right;
				}
				.minus {
					display: none;
					float: right;
				}
				&.open {
					background-color: #333333 !important;
					color: #ffffff;
					span {
						color: #ffffff;
					}
					.plus {
						display: none;
					}
					.minus {
						display: inherit;
						color: #ffffff;
					}
				}
				.expandOptions {
					background-color: #ffffff;
					span {
						padding: 16px 20px;
						color: $dark-grey;
					}
				}
			}

			.accordion{
				margin-bottom: 0;
				.title{
					margin: 0;
					padding: 15px;
					background-color: #ffff;
					&.open{
						background-color: #333333 !important;
						color: white;
					}
				}
				.contentHolder{
					position: relative;
				}
			}
		}
		&.valid{
			& > .title{
				// border: 2px solid $honda-green;
			}
		}
		&.invalid{
			& > .title{
				border: 2px solid $honda-red;
			}
		}
		.offsetClick{
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 1;
		}
	}
	.errorMessage{
		color: $honda-red;
		margin-top: 5px;
	}

}

.selectWrapper.disabled{
	.selectHolder {
		background-color: #eaeaea;
		& > .title {
			cursor: default;
			color: #d3d3d3;
		}
	}
}

.campaigns {
	margin-top: 32px;
}

.fc-white {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p {
		color: white;
	}
}

.fc-black {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p {
		color: black;
	}
}

.fc-grey {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p {
		color: black;
	}
	a {
		color: black !important; 
	}
}


.campaignBuilder .dynamic-content-styles {
	strong {
		font-weight: bold !important;
	}

	i {
		font-style: italic !important;
	}

	u {
		text-decoration: underline;	
	}

	p {
		font-size: 14px;
		line-height: 20px;
		margin-bottom: 10px;
		
		@media (max-width: 768px) {
			font-size: 13px;
			line-height: 18px;
		}
	}
	ul, ol {
		padding: 0;
		margin: 15px 0 25px 0;
		
		li {
			font-size: 15px;
			line-height: 23px;
			list-style-position: inside;

			margin: 0 0 10px 0; 
			padding: 0;
			
			list-style-type: disc;
			
			@media (max-width: 991px) {
				font-size: 15px;
				line-height: 23px;
			}
		}
	}
	
	ul li > ul, ul li > ol {
		margin-left: 15px;
	}

	& > ul > li {
		list-style-type: disc;
		& > ul > li {
			list-style-type: circle;
		}
	}

	& > ol {
		counter-increment: ol;
	}

	ol {
		list-style-type: none;
		margin: 0 0 25px 0;

		ol {
			margin: 15px 0 15px 15px;
		}
	}
	
	ol li {		
		list-style-type: none;
		margin-bottom: 15px;
		
		font-size: 16px;
		line-height: 1.63;
		
		@media(max-width: 768px) {
			font-size: 14px;
			font-weight: 600;
			line-height: 1.57;
		}

		&:before {
			margin-right: 5px;
		}
	}

	& > ol > li {
		counter-increment: item;

		&:before {
			content: counters(item, "", absolute) ".";
			margin-right: 5px;
		}
	}

	& > ol > li > ol {
		counter-reset: item2;

		& > li {
			counter-increment: item2;

			&:before {
				content: counters(item, ".", absolute) "." counters(item2, ".", absolute) " ";
			}

			& > ol > li {
				counter-increment: item3;

				&:before {
					content:  counters(item, ".", absolute) "." counters(item2, ".", absolute) "." counters(item3, ".", absolute) " ";
				}
			}
		}
	}

	h2 {
		font-size: 30px;
		font-weight: bold;
	}
	
	h3 {
		font-size: 20px;
		font-weight: bold;
	}
	
	h4 {
		font-size: 18px;
		font-weight: bold;
	}
	
	h5 {
		font-size: 16px;
		font-weight: bold;
	}

	img { 
		display: block;
		max-width: 100%;
		margin: 0 auto 15px auto;
	}

	table {
		width: auto;
		table-layout: auto;
		margin: 30px 0;
		max-width: 100%;
		min-width: 100%;
		// min-width: 800px;
		overflow-x: auto;
		display: inline-block;
		text-align: center;

		// &:after{
		// 	display: block;
		// 	content: '';
		// 	width: 100px;
		// 	height: 1px;
		// }

		@media(max-width: 1199px) {

		}

		th p, td p{
			margin: 0;
			padding: 0;
		}

		th, th p {
			font-size: 15px;
			line-height: 1.2;
			font-weight: 800;
			color: #fff;
			white-space: nowrap; 
		}
		th {
			background-color: $honda-red;
			border:none;
			border-radius: 0;
			padding: 8px 10px;

			text-align: center;
			// border-right: 1px solid #fff;	
			// border-left: 1px solid #fff;

			@media(max-width: 1199px) {
			}	
		}

		td, td p{
			color: #333333;
			font-size: 12px;
			line-height: 1.2;
		}

		td {
			border-radius: 0;
			text-align: center;
			border-bottom: 1px solid $lighter-grey;

			@media(max-width: 1199px) {
				min-width: 100px;
			}
		}

		tbody {
			background: #fff;
		}

		thead, body {
			tr {
			}
		}
	}
};

.campaignBuilderHolder {
	.row,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin: 0;
	}

	.card-content {
		.fromPrice {
			color: $honda-red;
			font-size: 16px;
			font-style: bold;
		}
		font-size: 16px;
	}
	&.fc-grey {
		.campaignBuilder .cbTitle {
			color: black;
		}
	}

	&.fc-white {
		#image-block-slider-new {
			@media(min-width: 1280px) {
				margin: 0 120px;
			}
			.slick-slide {
				height: auto;
				min-height: 100%;
				float: none;
				// background: #333;
				& > div {
					min-height: 100%;
					display: flex;
				}
				& > div > div {
					display: block !important;
				}
			}
			.slick-track {
    			display: flex !important;
			}
			.slick-slider {
				
			}
			.slide-inner {	
				background: transparent;
				border-radius: 0;
				min-height: 100%;
				display: flex;

				.copy {
					font-weight: normal;
				}

				@media(max-width: 1199px) {
					max-width: 400px;
					margin: 0 auto;
				}
				@media(max-width: 600px) {
					max-width: 100%;
				}
			}
			.image-slider-block-new {
				// background: #333;
				padding: 15px 15px 0 15px !important;
				background: #101010;
				.title {
					color: white;
					text-align: center;
				}
				.copy {
					color: white;
					padding: 0;
				}
				.slide-cta {
					a {
						color: white;
					}
				}
			}
		}
		.selectHolder {
			color: #FFF;

			svg {
				color: #FFF;
			}
		}

		.selectedResult {			
			color: #FFF;
		}

		input {
			background: #000;
			border: 2px solid #d8d8d8;
			color: #FFF;

			::placeholder {
				color: #999;
			}
		}

		.generalCheckbox, .htmlComponent {
			color: #FFF;
		}

		.accordion .contentHolder.open, .accordion .contentHolder.open p {
			color: #FFF !important;
			strong {
				font-weight: 900;
			}
		}
	}
	.campaignBuilder {
		// background-color: #1c1e21;
		.banner {
			margin: 64px 0;
		}

		.cbTitle {
			font-size: 36px;
			margin: 40px 0;
			// color: #fff;
			&.floatingHeader {
				margin-bottom: 64px;
			}
		}
		.cbDescription {
			// text-align: center;
			font-size: 18px;
			color: #ffffff;
			font-weight: 300;
			margin: 0;
			line-height: 35px;

			table {
				width: auto;
				margin: 30px auto;
				table-layout: auto;
				max-width: 100%;
				// min-width: 100%;
				// min-width: 800px;
				overflow-x: auto;
				display: inline-block;
				text-align: center;

				@media(max-width: 1199px) {
				}

				th p, td p{
					margin: 0;
					padding: 0;
				}

				th, th p {
					font-size: 15px;
					line-height: 1.2;
					font-weight: 800;
					color: #fff;
					white-space: nowrap; 
				}
				th {
					background-color: $honda-red;
					border:none;
					border-radius: 0;
					padding: 8px 10px;

					text-align: center;
					// border-right: 1px solid #fff;	
					// border-left: 1px solid #fff;

					@media(max-width: 1199px) {
					}	
				}

				td, td p{
					color: #333333;
					font-size: 12px;
					line-height: 1.2;
				}

				td {
					border-radius: 0;
					text-align: center;
					border-bottom: 1px solid $lighter-grey;

					@media(max-width: 1199px) {
						min-width: 100px;
					}
				}

				tbody{
					background: #fff;
				}

				thead, body {
					tr {
					}
				}
			}

			&.left {
				text-align: left !important;
			}
			&.center {
				text-align: center !important;
			}
			&.right {
				text-align: right !important;
			}
			p {
				a {
					color: white;
				}
			}

			@media only screen and (min-width: "1001px") {
				font-size: 22px;
				text-align: center;
				line-height: 25px;
			}
			@media only screen and (max-width: "1000px") {
				font-size: 22px;
			}
			@media only screen and (max-width: "700px") {
				font-size: 18px;
			}
			@media only screen and (max-width: "600px") {
				font-size: 16px;
			}
			@media only screen and (min-width: "600px") {
				font-size: 16px;
			}
		}
		.galleryHolder {
			width: 100%;
			height: 80vh;
			// overflow: hidden;
			overflow: auto;
			padding-bottom: 8px;
			position: relative;
			margin-top: 32px;
			//scrollbar////////
			scrollbar-color: #878787;
			scrollbar-width: thin;

			&::-webkit-scrollbar {
				border-radius: 10px;
				height: 10px;
				width: 8px;
			}

			&::-webkit-scrollbar-thumb {
				// background: #999;
				background: #f51638;
				border-radius: 10px;
			}

			&::-webkit-scrollbar-track {
				border-radius: 10px;
			}

			&.red-scroll {
				scrollbar-color: #f51638 transparent;
				scrollbar-width: thin;
		
				&::-webkit-scrollbar {
		
				}
				
				&::-webkit-scrollbar-thumb {
					background: #f51638;
				}
				
				&::-webkit-scrollbar-track {
					
				}
			}
			
			&.grey-scroll {
				scrollbar-color: #999 transparent;
				scrollbar-width: thin;
		
				&::-webkit-scrollbar {
		
				}
				
				&::-webkit-scrollbar-thumb {
					background: #999;
				}
				
				&::-webkit-scrollbar-track {
					
				}
			}
			
			.scrollarea {
				height: 100%;
				width: 100%;
				overflow: visible !important;
				position: relative;
				.scrollarea-content {
					overflow: hidden;
					height: 100%;
					outline: 0;
				}
				.scrollbar-container {
					z-index: 97 !important;
				}
			}
			//scrollbar////////
			.thumbnailGallery {
				height: 100%;
				white-space: nowrap;
				position: relative;

				.item {
					width: auto;
					height: 50%;
					float: left;
					border: 2px solid transparent;
					position: absolute;
					cursor: pointer;
					overflow: hidden;
					display: block;
					display: -ms-grid;
					&.itemSize-two {
						height: 100%;
					}
					&:first-child {
						border-left: 0;
					}
					.lazyLoad {
						height: 100%;
					}
					img {
						transition: all 0.5s ease-in-out;
						height: 100%;
						width: auto;
						position: relative;
						max-width: unset;
						&:after {
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							height: 100%;
							content: "";
							background-color: rgba(
								$color: #000000,
								$alpha: 0.75
							);
							opacity: 0;
						}
						&:hover {
							transform: scale(1.1);
							&:after {
								opacity: 1;
							}
						}
					}
				}
				&:after {
					content: "";
					display: table;
					clear: both;
				}
			}

			.galleryCarousel {
				position: absolute;
				top: 0;
				left: 0;
				height: 100%;
				width: 0%;
				overflow: hidden;
				z-index: 999;
				background-color: #000000;
				.asset {
					width: 100%;
					height: 100%;
					margin: 0 auto;
					text-align: center;
					position: absolute;
					top: 0;
					.lazyLoad {
						width: 100%;
						height: auto;
						position: absolute;
						left: 0;
						transform: translateY(-50%);
						top: 50%;
					}
					// &.exit{
					// 	opacity: 0 !important;
					// 	transform: translateX(0%) translateY(0px) translateZ(0px) !important;
					// }
				}

				.close {
					position: absolute;
					top: 10px;
					right: 15px;
					z-index: 9999;
					color: white;
					cursor: pointer;
				}
				.navArrow {
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					color: white;
					cursor: pointer;
					opacity: 0.8;
					transition: all 0.2s ease-in-out;
					padding: 20px;
					background-color: rgba($color: #000000, $alpha: 0.2);
					&.leftArrow {
						left: 5%;
					}
					&.rightArrow {
						right: 5%;
					}

					&:hover {
						opacity: 1;
						background-color: rgba($color: #000000, $alpha: 0.5);
					}
				}
			}
		}
		.campaignFeatures {
			.campaignFeaturesHolder {
				width: 100%;
				height: 80vh;
				overflow: hidden;
				position: relative;
				.feature {
					width: 100%;
					height: 100%;
					position: absolute;
					top: 50%;
					left: 0;
					transform: translateY(-50%);
					.featureImage {
						width: 100%;
						height: auto;
					}
					.contentHolder {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						background-color: rgba($color: #000000, $alpha: 0.3);
						.content {
							width: 70%;
							margin: 0 auto;
							color: white;
							padding: 5% 0;
							.copy {
								width: 75%;
							}
						}
					}
				}

				.featureSteps {
					position: absolute;
					margin: 0;
					top: 0;
					height: 100%;
					left: 5%;
					display: flex;
					align-content: center;
					align-items: center;
					counter-reset: stepCount;
					display: flex;
					justify-content: space-around;
					z-index: 98;
					flex-flow: column;
					.item {
						height: 100%;
						text-align: center;
						line-height: 2em;
						margin: 0 1em;
						display: inline-block;
						color: white;
						position: relative;
						width: 4em;
						cursor: pointer;
						.icon {
							position: absolute;
							width: 4em;
							height: 4em;
							top: 50%;
							transform: translateY(-50%);
							border: 1px solid white;
							border-radius: 2em;
							svg {
								width: 50%;
								height: 100%;
							}
							&:after {
								width: 4.6em;
								height: 4.6em;
								top: 50%;
								left: 50%;
								transform: translate(-50%, -50%);
								border: 1.5px solid white;
								border-radius: 50%;
								position: absolute;
								content: " ";
								opacity: 0;
								transition: opacity 0.3s ease-in-out;
							}
						}
						&:before {
							content: "";
							position: absolute;
							top: 0;
							left: 50%;
							width: 1px;
							height: calc(50% - 2em);
							background: white;
							z-index: -1;
							transform: translateX(-50%);
						}
						&:after {
							content: "";
							position: absolute;
							bottom: 0;
							left: 50%;
							width: 1px;
							height: calc(50% - 2em);
							background: white;
							z-index: -1;
							transform: translateX(-50%);
						}
						&.active {
							.icon {
								&:after {
									opacity: 1;
									box-shadow: 0 0 0 rgba(202, 202, 202, 0.8);
									animation: pulse 1.5s infinite;
								}
							}
						}
					}
				}
			}
		}

		.productFeatures {
			margin-bottom: 64px;
			.productFeaturesHolder {
				width: 100%;
				height: 80vh;
				overflow: hidden;
				position: relative;
				.feature {
					width: 100%;
					height: 100%;
					position: absolute;
					top: 50%;
					left: 0;
					transform: translateY(-50%);
					.featureImage {
						width: 100%;
						height: auto;
					}
					.contentHolder {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						background-size: cover;
						background-position: center center;
						.content {
							width: 70%;
							margin: 0 auto;
							color: white;
							padding: 5% 0;
							z-index: 9;
							position: relative;
							& h3 {
								color: white !important;
							}
							.copy {
								width: 75%;
								& p,
								a {
									color: white !important;
									-webkit-text-decoration-color: white;
									text-decoration-color: white;
									cursor: pointer;
								}
							}
						}
						&:before {
							clear: both;
							display: table;
							content: "";
							background-color: rgba(
								$color: #000000,
								$alpha: 0.3
							);
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							height: 100%;
						}
					}
				}

				.featureSteps {
					position: absolute;
					margin: 0;
					top: 0;
					height: 100%;
					left: 5%;
					display: flex;
					align-content: center;
					align-items: center;
					counter-reset: stepCount;
					display: flex;
					justify-content: space-around;
					z-index: 98;
					flex-flow: column;
					.item {
						height: 100%;
						text-align: center;
						line-height: 2em;
						margin: 0 1em;
						display: inline-block;
						color: white;
						position: relative;
						width: 4em;
						cursor: pointer;
						.icon {
							position: absolute;
							width: 4em;
							height: 4em;
							top: 50%;
							transform: translateY(-50%);
							border: 1px solid white;
							border-radius: 2em;
							svg.font-awesome {
								width: 50%;
								height: 100%;
							}
							.custom-icon {
								display: flex;
								align-items: center;
								justify-content: center;
								svg {
									height: 55px;
								}
							}
							&:after {
								width: 4.6em;
								height: 4.6em;
								top: 50%;
								left: 50%;
								transform: translate(-50%, -50%);
								border: 1.5px solid white;
								border-radius: 50%;
								position: absolute;
								content: " ";
								opacity: 0;
								transition: opacity 0.3s ease-in-out;
							}
						}
						&:before {
							content: "";
							position: absolute;
							top: 0;
							left: 50%;
							width: 1px;
							height: calc(50% - 2em);
							background: white;
							z-index: -1;
							transform: translateX(-50%);
						}
						&:after {
							content: "";
							position: absolute;
							bottom: 0;
							left: 50%;
							width: 1px;
							height: calc(50% - 2em);
							background: white;
							z-index: -1;
							transform: translateX(-50%);
						}
						&.active {
							.icon {
								&:after {
									opacity: 1;
									box-shadow: 0 0 0 rgba(202, 202, 202, 0.8);
									animation: pulse 1.5s infinite;
								}
							}
						}
					}
				}
			}
		}
		.productColours{
			width: 100%;
			margin-bottom: 64px;
			text-align: center;
			.productColoursHolder{
				overflow: hidden;
			}
			.colourHolder{
				width: 100%;
				margin: 0 auto;
				position: relative;
				height: 0;
				.car{
					width: 100%;
					position: absolute;
					top: 0;
					height: auto;
					text-align: center;
					padding: 0 12.5%;
					.placeholder{
						opacity: 0;
					}
					.carImage{
						position: absolute;
						top: 0;
						left: 50%;
						transform: translateX(-50%);
						transition: all 0.3s ease-in-out;
						&.static{
							opacity: 1;
						}
						&.hover{
							opacity: 0;
						}

					}
					&:hover{
						.static{
							opacity: 0;
						}
						.hover{
							opacity: 1;
						}	
					}
				}
			}
			.colours{
				width: 100%;
				text-align: center;
				.colour{
					width: 75px;
					height: 75px;
					overflow: hidden;
					border-radius: 50%;
					display: inline-block;
					border: 2px solid $light-grey;
					margin: 10px 16px;
					transition: transform 0.3s ease-in-out;
					.left{
						width: 50%;
						height: 100%;
					}
					.right{
						width: 50%;
						height: 100%;
					}
					&.active{
						transform: scale(1.1);
					}
				}
			}
			.colourName{
				height: 44px;
				overflow: hidden;
				margin-top: 16px;
				.title{
					text-transform: capitalize;
					text-align: center;
				}
				.type{
					text-transform: capitalize;
					text-align: center;
				}
			}
		}

		.copyWithImage {
			position: relative;
			.copyHolder {
				position: absolute;
				width: 50%;
				top: 50%;
				transform: translateY(-50%);
				padding: 0 5%;
				z-index: 9;
				&.pos-left {
					left: 0;
				}
				&.pos-right {
					right: 0;
				}
				.cbTitle {
					text-align: left;
				}
				text-align: left;
			}
		}
		.image-slider-block-new {
			padding: 0 15px !important;
			.content {
				padding: 15px;
			}

			.title { 
				color: #f51638;
				max-height: 60px;
				overflow: hidden;
				line-height: 20px;
				margin-bottom: 10px;
			}
			.copy { 
				// max-height: 234px; overflow: hidden;
				padding: 15px;
			}
			.asset {
				margin-bottom: 10px;
			}
			.slide-cta {
				padding-top: 25px;
				display: flex;
				gap: 5px;
				overflow: hidden;

				a {
					display: block;
					color: #FFF !important;
					text-align: center;
					font-size: 12px;
					padding: 12px;
					margin: 0;
				}

				@media(max-width: 420px) {
					flex-direction: column;
					a {
						
					}
				}
			}
		}
		.image-block-slider-container {
			padding-top: 60px;

			@media(max-width: 991px) {
				padding-top: 45px;
				
				.navItem {
					cursor: pointer;

					svg {
						height: 30px;
					}

					&.navLeft {
						left: -55px;
					}
					&.navRight {
						right: -55px;
					}
				}
			}

			.slideHolder {
				display: flex;
				justify-items: center;
				align-items: stretch;
				gap: 20px;
				height: auto !important;

				@media(max-width: 991px) {
					gap: 0;
				}

				.slide {
					flex-grow: 1;
					height: auto !important;
					padding: 0 !important;

					border: 1px solid #d8d8d8;

					@media(max-width: 991px) {
						display: block; width: 100% !important;
					}

					.image-slider-block {
		
						.content {
							padding: 15px;
						}
		
						.title { 
							color: #f51638;
							max-height: 60px;
							overflow: hidden;
							line-height: 20px;
							margin-bottom: 10px;
						}
						.copy { 
							max-height: 234px; overflow: hidden;
							padding: 15px;
						}
						.asset {
							margin-bottom: 10px;
						}
						.slide-cta {
							margin-top: 20px;
							display: flex;
							gap: 5px;
							overflow: hidden;
		
							a {
								display: block;
								color: #FFF !important;
								text-align: center;
								font-size: 12px;
								padding: 12px;
							}
						}
					}	
				}
			}
			
			.rowSlider .bullets {
				@media(max-width: 880px) {
					top: 100%;
					padding: 0;
					span {
						background-color: #878787;
					}
					.content {
						
					}
				}
			}
			@media(max-width: 880px) and (min-width: 601px) {
				padding-bottom: 60px;
			}
		}
	}
}

:root {
	--sliderBtnSize: 40;
}

#image-block-slider-new {
	padding-top: 20px;

	.arrow-left, .arrow-right {
		position: absolute;
		right: 0;
		box-shadow: 0 5px 5px 0 #000;
		top: 30%;
		z-index: 3;
		transform: translateY(-50%);
		display: inline-flex;
		
		height: var(--btnSize, 40)px;
		width: var(--btnSize, 40)px;
		background: #FFF;
		border: none !important;
		color: #000;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		transition: all 0.3s ease-in-out;
		
		svg {
			transition: all 0.3s ease-in-out;
			color: #000;
			width: 30px;
			height: auto;
		}
		
		&:hover, &:active, &:focus {
			background: #000;
			color: #FFF;
			
			svg {
				color: #FFF
			}
		}  
		
		@media(max-width: 1199px) {
			transform: translateY(-50%);
		}
		
		@media(max-width: 991px) {
			transform: translateY(-50%);
		}
		
		@media(max-width: 768px) {
			top: 40%;
			transform: translateY(-50%);
		}
		
		@media(max-width: 400px){
			transform: translateY(-50%);
		}
	}
	
	.arrow-left {
		right: auto;
		left: 0;
		transform: translateY(-50%);
		
		@media(max-width: 991px) {
		transform: translateY(-50%);
		}
		
		@media(max-width: 768px) {
		top: 40%;
		transform: translateY(-50%);
		}
		
		@media(max-width: 400px){
		transform: translateY(-50%);
		}
	}

	.container {
		position: relative;
		width: 100%;
		
		.slick-dots {
			li {
				cursor: auto;
			}
			
			li button {
				:before {
					content: none;
				}
			
				height: 12px;
				width: 12px;      
				border-radius: 20px;
				background-color: rgba(219, 219, 219, 0.18);
			}
			.slick-active button {
				background-color: #bdc646;
			}
		}
		
		.slick-active {
			z-index: 2;
		}
		
		@media(min-width: 769px) {
			&.low-slide-count {
				.slide {
					.slide-inner {
						transform: scale(1, 1) !important;
						box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.1);  
					}
					
					&.active {
						.slide-inner {
							
						}
					}
					
				}
			}
			
			&.no-additional-slides, &.low-slide-count {
				.slide {
					.slide-inner {
						transform: scale(1, 1) !important;
						box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.1);  
					}
					
					&.active {
						.slide-inner {
							
						}
					}
				}
			}
		}
		
		@media(max-width: 1199px) {
			
		}
	}
	.slide {
		padding: 80px 20px 120px 20px;
		transition: all 0.3s ease-in-out;
		
		&:focus, &:active:focus {
		  outline: none !important;
		}
		
		&.active {    
		  z-index: 3;
		  position: relative;    
			
		  .slide-inner {
			//box-shadow: 0 20px 20px 0 rgba(0, 0, 0, 0.1);
			//box-shadow: 0 20px 60px 0 rgba(0, 0, 0, 0.1);      
			
			@media(max-width: 991px) {
			  transform: none;
			//   box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.1);
			}
		  }
		}  
		
		@media(max-width: 991px){
		  padding: 30px 20px 50px 20px;
		}
		
		@media(max-width: 768px) {
		  padding: 30px 20px 50px 20px;
		}
	}

	.slide-inner {
		border-radius: 18px;
		background: #FFF;
		padding: 20px;
		transition: all 0.3s ease-in-out;
		transform: scale(1, 1);
		// box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.1);  
		
		&:focus, &:active:focus {
			outline: none !important;
		}
		
		@media(max-width: 1600px) {
			
		}
		
		@media(max-width: 991px) {    
			transform: none;    
		}
		
		@media(max-width: 768px) {    
			transform: none;    
		}
		
		@media(max-width: 460px) {
			
		}
	}
}

.scrollarea {
	.scrollbar-container {
		position: absolute;
		background: none;
		opacity: 0.5;
		z-index: 9999;
		-webkit-transition: all 0.4s;
		transition: all 0.4s;
		&.vertical {
			width: 10px;
			height: 100%;
			right: 0;
			top: 0;
			.scrollbar {
				width: 8px;
				height: 20px;
				background: $dark-grey;
				margin-left: 1px;
			}
		}
		&.horizontal {
			width: 100%;
			height: 10px;
			left: 0;
			bottom: -10px;
			.scrollbar {
				width: 20px;
				height: 8px;
				background: $dark-grey;
				margin-top: 1px;
			}
		}
		&:hover,
		&.active {
			background: $light-grey;
			opacity: 1 !important;
		}
	}
}

.scrollarea {
	overflow: hidden;
	position: relative;
	width: 100%;
	height: 100%;
	.scrollarea-content {
		overflow: hidden;
		outline: 0;
	}
}

.scroll-red {
	.scrollarea {
		.scrollbar-container {
			&.vertical {
				.scrollbar {
					background: $honda-red;
				}
			}
			&.horizontal {
				width: 100%;
				height: 10px;
				left: 0;
				bottom: -10px;
				.scrollbar {
					width: 20px;
					height: 8px;
					background: $honda-red;
					margin-top: 1px;
				}
			}
			&:hover,
			&.active {
				background: #fa8b9c;
				opacity: 1 !important;
			}
		}
	}
}

@-webkit-keyframes pulse {
	0% {
		-webkit-box-shadow: 0 0 0 0 rgba(202, 202, 202, 0.4);
	}
	70% {
		-webkit-box-shadow: 0 0 0 10px rgba(202, 202, 202, 0);
	}
	100% {
		-webkit-box-shadow: 0 0 0 0 rgba(202, 202, 202, 0);
	}
}

@keyframes pulse {
	0% {
		-moz-box-shadow: 0 0 0 0 rgba(202, 202, 202, 0.4);
		box-shadow: 0 0 0 0 rgba(202, 202, 202, 0.4);
	}
	70% {
		-moz-box-shadow: 0 0 0 10px rgba(202, 202, 202, 0);
		box-shadow: 0 0 0 10px rgba(202, 202, 202, 0);
	}
	100% {
		-moz-box-shadow: 0 0 0 0 rgba(202, 202, 202, 0);
		box-shadow: 0 0 0 0 rgba(202, 202, 202, 0);
	}
}

@-webkit-keyframes cog {
	0% {
		-webkit-box-shadow: 0 0 0 0 rgba(202, 202, 202, 0.4);
	}
	70% {
		-webkit-box-shadow: 0 0 0 10px rgba(202, 202, 202, 0);
	}
	100% {
		-webkit-box-shadow: 0 0 0 0 rgba(202, 202, 202, 0);
	}
}

@keyframes cog {
	0% {
		-moz-box-shadow: 0 0 0 0 rgb(204, 94, 94, 0.6);
		box-shadow: 0 0 0 0 rgba(156, 153, 153, 0.616);
	}
	70% {
		-moz-box-shadow: 0 0 0 10px rgba(202, 202, 202, 0.4);
		box-shadow: 0 0 0 10px rgba(202, 202, 202, 0);
	}
	100% {
		-moz-box-shadow: 0 0 0 0 rgba(202, 202, 202, 0.2);
		box-shadow: 0 0 0 0 rgba(202, 202, 202, 0);
	}
}

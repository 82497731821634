%toolHolder {
	color: $dark-grey;
	text-align: center;
	
	a{
		display: inline-block;
	}
	.tool{
		position: relative;
		margin: 0 auto;
		width: 100px;
		height: 100px;
		font-size: 50px;
		text-align: center;
		background-color: $white-grey;
		border: 3px solid $light-grey;
		border-radius: 50%;
		justify-content: center;
		color: $dark-grey;
	}
	.title{
		// color: $dark-grey;
		text-align: center;
		margin: 16px 0;
		font-family: $montserrat;
		font-weight: 500;
	}
}

.flexcontainer {
	display: flex;
	flex-flow: row wrap;
}

.cta_tool {
	@extend %toolHolder;
}

.shoppingTools{
	.toolHolder{
		color: $dark-grey;
		text-align: center;
		a{
			display: inline-block;
		}
		.tool{
			position: relative;
			margin: 0 auto;
			width: 200px;
			height: 200px;
			font-size: 100px;
			text-align: center;
			background-color: $white-grey;
			border: 3px solid $light-grey;
			border-radius: 50%;
			justify-content: center;
			color: $dark-grey;
			.hover{
				opacity: 0;
				transition: 0.3s ease-in;
				position: absolute;
				top: 0;
				left: 50%;
				width: 100%;
				border: 2px solid $white-grey;
				.item{
					font-family: $montserrat;
					display: block;
					font-size: 15px;
					width: 100%;
					padding: 10px;
					background-color: #e6e6e6;
					border: 2px solid $white-grey;
					color: black;
					transition: 0.3s ease-in;
					&:hover{
						background-color: #a29e9e;
						border-color: #a29e9e;
					}
				}
			}
			&:hover{
				.hover{
					opacity: 1;
				}
			}
		}
		.title{
			color: $dark-grey;
			text-align: center;
			margin: 16px 0;
			font-family: $montserrat;
			font-weight: 500;
		}
	}
}


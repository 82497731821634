.stepper_wrapper {
    border-bottom: .21rem solid #d8d8d8;
    padding-top: "500px" !important;
	margin-top: 128px !important;
}

.tab {
    min-height: 50vh;
}

.tabs_loader {
    min-height: 50vh;
}

.tab .formBuilder .findADealer {
    height: auto;
    margin: 50px 0;
    .results {
        .row.dealer__container {
            margin: 10px;
            .col {
                background-color: white;
                &.dealersHolder {
                    padding: 35px 35px 0;
                    border: 2px solid #d8d8d8;
                    .dealer {
						.intro {
							.titleHolder {

								.distance {
									font-size: 14px;
								}
								.address {
									font-size: 14px;
								}
							}
							&:after {
								clear: both;
								display: table;
								content: "";
							}
						}
						.info {
							margin-top: 15px;
							.row {
								.col {
									font-size: 16px;
									margin: 5px 0px;
									a {
										color: black;
									}
									svg {
										margin-right: 15px;
										font-size: 18px;
									}
								}
							}
						}
						.buttons {
							padding-top: 15px;
							border-top : 1px solid $light-grey;
                            a {
                                margin-top: 20px;
                            }
						}


						&.active {
							.intro {
								.location {
									color: $honda-red;
								}
							}
						}
					}
                }
            }
        }
    }
}

.steps_wrapper {
    .customContainer .hondaFinance_logo {
        padding: 10px 0;
        max-height: 100px;
        max-width: 300px;
        img {
            width: 100%;
            height: 100%;
        }
    }
}

.steps_wrapper_horizontal {
    display: flex;
    .steps_item {
        margin-right: 2rem;
        padding-right: 0.5rem;
        position: relative;
        color: #d8d8d8;
        span.active {
            width: 100%;
            height: 0.2rem;
            position: absolute;
            background-color: #f51638;
        }
    }
}

.sticky_progress {
    background-color: transparent;
    .stepper_mobile_container {
        position: relative;
        .steps_wrapper_mobile {
            display: flex;
            flex-direction: column;
            height: 70px;
            .customContainer .page_label {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin: 10px 10px;
                div:nth-child(1) {
                    border: .21rem solid $honda-red;
                    // padding: 10px 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    width: 50px;
                    height: 50px;
                    margin-right: 10px;
                    letter-spacing: .08rem;
                    font-size: 14px;
                    font-weight: bolder;
                    h6 {
                        margin: 0;
                    }
                }
                div:nth-child(2) {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    h4 {
                        margin: 0 !important;
                    }
                }
            }
            .stepper_progress {
                margin-top: 20px;
                width: 100%;
                position: absolute;
                bottom: 0;
            }
        }
    }
}

.estimates_wrapper {
    padding: 1.2rem;
    border-style: solid;
    border-color: #e0e4ed;
	background-color: #fdfdfd;
}

.estimate_value--group {
    position: relative;
	margin: 1.4rem 0;
	.estimate_value--title{
		font-weight: 900;
		font-size: 1.3rem;
	}
	.estimate_value--value{
		font-size: 1.28rem;
		margin: 0;
		color: $dark-grey !important;
		font-weight: 300;
	}

	&.total{
		padding-bottom: 1.4rem;
		border-top: 1px solid $honda-red;
		border-bottom: 1px solid $honda-red;
	}
}

.finance-applications--conditions{
	p {
		margin: 12px 0;
		a {
			color: $honda-red;
		}
	}
}

.estimate_value--group.total::after{
	content:"";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 0.5em;
    border-top: 1px solid $honda-red;
    z-index: 1;
}


.range_slider {
    -webkit-appearance: none;
    width: 100%;
    height: .3rem;
    outline: none;
    background: linear-gradient(90deg, rgb(214, 214, 214) 60%, rgb(214, 214, 214) 60%);

    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 4rem;
        height: 2rem;
        position: relative;
        background: #f51638;
        cursor: pointer;
    }

    &::-moz-range-thumb {
        width: 3rem;
        height: 2rem;
        position: relative;
        background: #f51638;
        cursor: pointer;
    }
}

.tabs_loader {
    display: flex;
    justify-content: center;
    .loader svg {
        // text-align: center;
        color: #f51638;
    }
}



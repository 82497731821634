.newsHolder {
	margin-top: 55px;
	display: flex;
	flex-flow: wrap;

	.featuredNewsCard {
		margin-bottom: 20px;
		margin-left: unset !important;

		.customCard {
			position: relative;
			display: block;
			background-color: $white-grey;
			width: 100%;
			height: 100%;
		}

		.newsFeatured {
			width: 100%;
			padding: 2.5%;
			margin-bottom: 50px;

			.category {
				margin-top: 0;
				color: $light-grey;
			}
			.title {
				color: $dark-grey;
				.date {
					color: $lighter-grey;
					font-family: $lato;
					font-size: 15px;
				}
			}
			.excerpt {
				color: $dark-grey;
			}
			.readMore {
				color: $honda-red;
				text-transform: uppercase;
				font-weight: bold;
				position: absolute;
				bottom: 1.5%;
				left: 2.5%;
			}
		}
	}
}

.newsBuilderHolder, .legalBuilderHolder {
	.copy {
		figure {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			margin-bottom: 25px;
			max-width: 100%;

			@media(max-width: 768px) {
				margin: 0 10px 25px 10px;
			}

			img {				
				max-width: 100%;
			}

			figcaption {	
				flex: 1 0 auto;	
				text-shadow: 1px 1px 2px #000;
				box-shadow: 4px 4px 1px #000;
				color: #FFF;
				font-weight: bold;
				padding: 5px 10px;
				background-color: #555;
				font-size: 16px;
				font-style: italic;				
				line-height: 24px;								
				padding: 10px;
				max-width: 100%;
				
				@media(max-width: 768px) {
					font-size: 14px;
				}
			}
		}
		
		strong {
			font-weight: 900 !important;
		}
	
		i {
			font-style: italic !important;
		}
	
		u {
			text-decoration: underline;	
			text-decoration-thickness: 2px;
		}
	
		p {
			font-size: 15px;
			line-height: 23px;
			margin-bottom: 15px;
			margin: 0 0 25px 0;
			padding: 0;
			font-weight: 400;

			@media (max-width: 991px) {
				font-size: 15px;
				line-height: 23px;
			}
		}

		u, p > a, li > a {
			text-decoration-skip-ink: none;
		}

		p > a, li > a {
			text-decoration: underline;			
			text-decoration-color: #f51638;	
			text-decoration-thickness: 2px;
			transition: all 0.2s ease-in;

			&:hover {				
				text-decoration-color: #fff !important;	
			}
		}
		
		ul, ol {
			padding: 0;
			margin: 15px 0 25px 0;
			
			li {
				font-size: 15px;
				line-height: 23px;
				list-style-position: inside;
	
				margin: 0 0 10px 0; 
				padding: 0;
				
				list-style-type: disc;
				
				@media (max-width: 991px) {
					font-size: 15px;
					line-height: 23px;
				}
			}
		}
		
		ul li > ul, ul li > ol {
			margin-left: 15px;
		}

		& > ul > li {
			list-style-type: disc;
			& > ul > li {
				list-style-type: circle;
			}
		}

		
		& > ol {
			counter-increment: ol;
		}

		ol {
			list-style-type: none;
			margin: 0 0 25px 0;

			ol {
				margin: 15px 0 15px 15px;
			}
		}
		
		ol li {		
			list-style-type: none;
			margin-bottom: 15px;
			
			font-size: 16px;
			line-height: 1.63;
			
			@media(max-width: 768px) {
				font-size: 14px;
				font-weight: 600;
				line-height: 1.57;
			}

			&:before {
				margin-right: 5px;
			}
		}

		& > ol > li {
			counter-increment: item;

			&:before {
				content: counters(item, "", absolute) ".";
				margin-right: 5px;
			}
		}

		& > ol > li > ol {
			counter-reset: item2;

			& > li {
				counter-increment: item2;

				&:before {
					content: counters(item, ".", absolute) "." counters(item2, ".", absolute) " ";
				}

				& > ol > li {
					counter-increment: item3;

					&:before {
						content:  counters(item, ".", absolute) "." counters(item2, ".", absolute) "." counters(item3, ".", absolute) " ";
					}
				}
			}
		}

		h2, h1 {
			font-size: 34px;
			line-height: 42px;
			margin: 0 auto 25px auto;
			
			@media(max-width: 768px) {
				font-size: 24px;
			}
		}
		
		h3 {
			font-size: 28px;
			line-height: 40px;
			font-weight: 400;
			margin: 0 auto 25px auto;
			
			@media(max-width: 768px) {
				font-size: 24px;
			}
		}
		
		h4 {
			margin: 0 0 15px 0;
			line-height: 1.25;
			font-size: 24px;
			font-weight: 800;
			
			@media(max-width: 768px) {
				font-size: 18px;
			}
		}
		
		h5 {
			font-size: 18px;
			font-weight: 600;
			line-height: 22px;

			margin-bottom: 10px;

			@media(max-width: 768px) {
				font-size: 17px;
				line-height: 20px;
			}
		}

		table {
			margin: 30px 0;
			table-layout: auto;
			max-width: 100%;
			// min-width: 100%;
			// min-width: 800px;
			overflow-x: auto;
			width: auto;
			display: inline-block;
			text-align: center;

			@media(max-width: 1199px) {
			}

			th p, td p{
				margin: 0;
				padding: 0;
			}

			th, th p {
				font-size: 15px;
				line-height: 1.2;
				font-weight: 800;
				color: #fff;
				white-space: nowrap; 
			}
			th {
				background-color: $honda-red;
				border:none;
				border-radius: 0;
				padding: 8px 10px;

				text-align: center;
				// border-right: 1px solid #fff;	
				// border-left: 1px solid #fff;

				@media(max-width: 1199px) {
				}	
			}

			td, td p{
				color: #333333;
				font-size: 12px;
				line-height: 1.2;
			}

			td {
				border-radius: 0;
				text-align: center;
				border-bottom: 1px solid $lighter-grey;

				@media(max-width: 1199px) {
					min-width: 100px;
				}
			}

			tbody{
				background: #fff;
			}

			thead, body {

				tr {
				}
			}
		}	
	}
}

.newsBuilderHolder {
	.newsBanner {
		width: 100%;
		.goBack {
			margin-top: 55px;
			color: $honda-red;
			text-transform: uppercase;
			svg {
				font-size: 20px;
			}
			span {
				margin-left: 10px;
			}
		}
		.date {
			color: $lighter-grey;
		}
	}
	.newsBuilder {
		.title {
			text-align: left;
		}
		.titleWithDescription {
			text-align: left;
			a {
				color: $dark-grey;
				text-decoration: underline;
			}
		}
		.copy {
			
		}
		.table {
			table{
				table-layout: fixed;
			}
			pre {
				font-family: "Lato", sans-serif;
				margin: 0;
			}
			th {
				padding-left: 16px;
				font-size: 16px;
				color: #333333;
				font-weight: bold;
			}

			td {
				padding-left: 16px;
				font-size: 12px;
				color: #333333;
			}
			td,
			th {
				border-right: 1px solid #f4f4f4;
				border-left: 1px solid #f4f4f4;
				border-bottom: 1px solid #f4f4f4;
			}

			thead {
				tr {
					background: #f5f5f5;
					border-bottom: #f5f5f5;
					font-size: 1.4rem;
				}
			}
		}

		.fullBleedImage{
			margin-bottom: 55px;
		}
		.grid{
			display: flex;
			flex-flow: wrap;
		}
	}
}

.buttonActions{
	.hButton{
		width: 25%;
		margin-bottom: 20px;
	}
}
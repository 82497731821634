.sideNavHolder {
	position: fixed;
	left: 1%;
	bottom: 2%;
	z-index: 1000;
	.sideNavItem {
		position: relative;
		margin-bottom: 20px;
		display: table;
		border-radius: 100px;
		background-color: $white-grey;
		border: 4px solid $light-grey;
		width: fit-content;
		transition: width 0.3s ease-in-out;
		margin-right: auto;
		.icon {
			width: 70px;
			height: 70px;
			color: $dark-grey;
			font-size: 1.8em;
			line-height: 60px;
			text-align: center;
			display: flex;
			align-items: center;
			justify-content: center;
			transition: 0.3s ease-in-out;
			float: left;
		}
		.content {
			float: right;
			overflow: hidden;
			white-space: nowrap;
			opacity: 0;
			height: 70px;
			max-height: 70px;
			display: block;
			justify-content: left;
			align-items: center;
			color: $dark-grey;
			// p{
			// 	padding: 7px 0 // breaking valign center on iPhone, dont need it anyway -> .content {align-items: center;}
			// }
		}
		&.recall {
			background-color: $honda-red;
			border-color: $honda-red;
			.icon {
				margin: 0;
				transition: 0.3s ease-in-out;
				img {
					width: 90%;
					height: auto;
				}
			}
			.content {
				color: white;
				display: block;
				max-height: unset;
				height: unset;
				.title {
					margin: 0;
				}
			}
			&:hover {
				.icon {
					margin: 15px 0;
					padding-top: 20px;
					padding-bottom: 20px;
				}
				.content {
					padding-right: 30px;
					padding-left: 20px;
					padding-top: 18px;
				}
			}
		}

		&:hover {
			width: auto;
			.icon {
				font-size: 2.5em;
			}
			.content {
				opacity: 1;
				padding-right: 20px;
				padding-left: 0;
			}
		}
		&:after {
			content: "";
			display: table;
			clear: both;
		}
		&:last-child {
			margin-bottom: 0;
		}
	}
}
.bannerCarousel{
	width: 100%;
	margin-bottom: 64px;
	&.category{
		margin-bottom: 0 !important;
	}
	.carouselThumbnail{
		width: 33.33%;
		float: left;
		border: 2px solid white;
		border-bottom: 0;
		border-left-width: 1.5px;
		border-right-width: 1.5px;
		&:first-child {
			border-left: 0;
			border-right-width: 2.5px;
		}
		&:last-child {
			border-right: 0;
			border-left-width: 2.5px;
		}
	}
	.carouselSlider{
		position: relative;
		margin-bottom: 2px;
		.exploreClick{
			position: absolute;
			bottom: 0;
			left: 0;
			background-color: rgba($color: $primary_navigation_backgroundColor, $alpha: 0.5);
			width: 100%;
			padding: 15px 7.5%;
			z-index: 99;
			text-align: right;
			a{
				color: white;
				font-size: 16px;
				font-weight: bold;
				transition: color 0.1s ease-in;
				svg{
					margin-left: 16px;
				}
				&:hover{
					color: $honda-red;
				}
			}
		}
	}
	&:after{
		content: "";
		clear: both;
		display: table;
	}
}
%specTable{
	border-spacing: 0;
	border-collapse: separate;
	width: 100%;
	table-layout: fixed;
	font-size: 15px;
};

%tableRow{
	border: 1px solid #f5f5f5;
	transition: all 0.5s;
};

/* cars deals */
.categoryBuilderHolder.deals {
	.copy {
		h2 { margin: 45px 0 45px 0;} 

		.dynamicCopy {
			margin-bottom: 60px; 

			figure {
				display: flex;
				justify-content: center;
				align-items: center;
				flex-direction: column;
				margin-bottom: 25px;
				max-width: 100%;

				@media(max-width: 768px) {
					margin: 0 10px 25px 10px;
				}

				img {				
					max-width: 100%;
				}

				figcaption {	
					flex: 1 0 auto;	
					text-shadow: 1px 1px 2px #000;
					box-shadow: 4px 4px 1px #000;
					color: #FFF;
					font-weight: bold;
					padding: 5px 10px;
					background-color: #555;
					font-size: 16px;
					font-style: italic;				
					line-height: 24px;								
					padding: 10px;
					max-width: 100%;
					
					@media(max-width: 768px) {
						font-size: 14px;
					}
				}
			}
			
			strong {
				font-weight: 900 !important;
			}
		
			i {
				font-style: italic !important;
			}
		
			u {
				text-decoration: underline;	
				text-decoration-thickness: 2px;
			}
		
			p {
				font-size: 15px;
				line-height: 23px;
				margin-bottom: 15px;
				margin: 0 0 15px 0;
				padding: 0;
				font-weight: 400;

				@media (max-width: 991px) {
					font-size: 15px;
					line-height: 23px;
				}
			}

			u, p > a, li > a {
				text-decoration-skip-ink: none;
			}

			p > a, li > a {
				text-decoration: underline;			
				text-decoration-color: #f51638;	
				text-decoration-thickness: 2px;
				transition: all 0.2s ease-in;

				&:hover {				
					text-decoration-color: #fff !important;	
				}
			}
			
			ul, ol {
				padding: 0;
				margin: 15px 0 25px 0;
				
				li {
					font-size: 15px;
					line-height: 23px;
					list-style-position: inside;
		
					margin: 0 0 10px 0; 
					padding: 0;
					
					list-style-type: disc;
					
					@media (max-width: 991px) {
						font-size: 15px;
						line-height: 23px;
					}
				}
			}
			
			ul li > ul, ul li > ol {
				margin-left: 15px;
			}

			& > ul > li {
				list-style-type: disc;
				& > ul > li {
					list-style-type: circle;
				}
			}

			
			& > ol {
				counter-increment: ol;
			}

			ol {
				list-style-type: none;
				margin: 0 0 25px 0;

				ol {
					margin: 15px 0 15px 15px;
				}
			}

		}
	}
}

.productBuilderHolder {
	.row,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin: 0;
	}

	.card-content {
		.fromPrice {
			color: $honda-red;
			font-size: 16px;
			font-style: bold;
		}
		font-size: 16px;
	}

	.productBuilder {
		.cbTitle {
			font-size: 36px;
			text-align: center;
			margin: 40px 0;
			&.floatingHeader {
				margin-bottom: 64px;
			}
		}
		.cbDescription {
			text-align: center;
			font-size: 16px;
		}
		.specificationSection {
			.cbTitle{
				margin-bottom: 0;
			}
			.cbDescription{
				text-align: center;
			}
			.compare {
				font-size: 20px;
			}
			.input-field {
				margin: 0;
				padding: 0 16px !important;
				input {
					text-indent: 10px;
					&:focus {
						border-bottom: 1px solid #949494;
					}
					&:disabled {
						border-bottom: 1px solid #949494;
					}
				}
			}
			.moreOptions {
				text-transform: uppercase;
				padding: 16px 0;
				margin: 16px 0;
				border-top: 1px solid #f5f5f5;
				border-bottom: 1px solid #f5f5f5;
				font-size: 12px;
				.col {
					padding: 0 16px;
					width: auto;
					div {
						cursor: pointer;
						width: auto;
					}
					svg {
						margin-right: 8px;
					}
					.highlighDifferences {
						transition: all 0.5s;
						&.active {
							color: $honda-red;
							.activeColor {
								fill: $honda-red;
							}
						}
					}
					.expandAll {
						justify-content: flex-end;
						.plus {
							display: inline-block;
						}
						.remove {
							display: none;
						}
						&.expanded {
							.remove {
								display: inline-block;
							}
							.plus {
								display: none;
							}
						}
					}
				}
			}
			.specifications {
				margin: 32px 0;
				li {
					margin-bottom: 8px;
				}
				.title {
					background-color: #f5f5f5;
					border-bottom: none;
					font-weight: bold;
					font-size: 16px;
					position: relative;
				}
				.contentHolder {
					border-bottom: 1px solid #f5f5f5;
					padding: 0;
					font-size: 12px;
					
					.customTable {
						@extend %specTable;
						.primary--row{
							@extend %tableRow;
							.primary--col{
								padding: 0;
								border: 1px solid #f5f5f5;
								margin: 0;
								.primary--tb{
									@extend %specTable;
								}
							}
						}

						tr {
							width: 100%;
							td {
								width: 25%;
							}
						}
						.mainRow {
							border: 1px solid #f5f5f5;
							transition: all 0.5s;
							td {
								padding: 8px;
								border: 1px solid #f5f5f5;
								&.heading {
									font-weight: bold;
								}
							}
						}
						.childRow {
							border: 1px solid #f5f5f5;
							transition: all 0.5s;
							td {
								padding: 8px;
								border: 1px solid #f5f5f5;
								&.heading {
									padding-left: 16px;
								}
								&.heading2 {
									padding-left: 32px;
								}
							}
						}
						.value {
							text-align: left;
						}
						.difference {
							background-color: #d8d8d8;
							border-color: #f5f5f5;
							td {
								border-color: #f5f5f5;
							}
						}
					}
				}
			}
		}

		.productFeatures {
			margin-bottom: 64px;
			.productFeaturesHolder {
				width: 100%;
				height: 80vh;
				overflow: hidden;
				position: relative;
				.feature {
					width: 100%;
					height: 100%; 
					max-height: 100%;
					position: absolute;
					top: 50%;
					left: 0;
					transform: translateY(-50%);
					.featureImage {
						width: 100%;
						height: auto;
					}
					.faded {
						background-color: rgba(0,0,0,0.4);
						height: 100%;
						width: 100%;							
					}
					.contentHolder {
						width: 100%;
						height: 100%;
						background-size: cover;
    					background-position: center center;

						.content {
							position: relative;
							width: 70%;
							margin: 0 auto;
							color: white;
							padding: 5% 0;
							z-index: 9;
							position: relative;
							height: 100%;
							overflow-y: auto;		
							h3 {
								text-shadow: 2px 2px 1px #000;
								margin-bottom: 10px;
							}					
							
							.copy {
								width: 75%;
								text-shadow: 2px 2px 1px #000;
								p a {
									color: white;
									-webkit-text-decoration-color: white;
									text-decoration-color: white;
									cursor: pointer;
								}
							}
						}
						&:before{
							clear: both;
							display: table;
							content: "";
							background-color: rgba($color: #000000, $alpha: 0.3);
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							height: 100%;
						}
					}
				}

				.featureSteps {
					position: absolute;
					margin: 0;
					top: 0;
					height: 100%;
					left: 5%;
					display: flex;
					align-content: center;
					align-items: center;
					counter-reset: stepCount;
					display: flex;
					justify-content: space-around;
					z-index: 98;
					flex-flow: column;
					.item {
						height: 100%;
						text-align: center;
						line-height: 2em;
						margin: 0 1em;
						display: inline-block;
						color: white;
						position: relative;
						width: 4em;
						cursor: pointer;
						.icon {
							position: absolute;
							width: 4em;
							height: 4em;
							top: 50%;
							transform: translateY(-50%);
							border: 1px solid white;
							border-radius: 2em;
							svg.font-awesome {
								width: 50%;
								height: 100%;
							}
							.custom-icon {
								display: flex;
								align-items: center;
								justify-content: center;
								svg {
									height: 55px;
								}
								
							}
							&:after {
								width: 4.6em;
								height: 4.6em;
								top: 50%;
								left: 50%;
								transform: translate(-50%, -50%);
								border: 1.5px solid white;
								border-radius: 50%;
								position: absolute;
								content: " ";
								opacity: 0;
								transition: opacity 0.3s ease-in-out;
							}
						}
						&:before {
							content: "";
							position: absolute;
							top: 0;
							left: 50%;
							width: 1px;
							height: calc(50% - 2em);
							background: white;
							z-index: -1;
							transform: translateX(-50%);
						}
						&:after {
							content: "";
							position: absolute;
							bottom: 0;
							left: 50%;
							width: 1px;
							height: calc(50% - 2em);
							background: white;
							z-index: -1;
							transform: translateX(-50%);
						}
						&.active {
							.icon {
								&:after {
									opacity: 1;
									box-shadow: 0 0 0 rgba(202, 202, 202, 0.8);
									animation: pulse 1.5s infinite;
								}
							}
						}
					}
				}
			}
		}
		.productColours{
			width: 100%;
			margin-bottom: 64px;
			.productColoursHolder{
				overflow: hidden;
			}
			.colourHolder{
				width: 100%;
				margin: 0 auto;
				position: relative;
				height: 0;
				.car{
					width: 100%;
					position: absolute;
					top: 0;
					height: auto;
					text-align: center;
					padding: 0 12.5%;
					.placeholder{
						opacity: 0;
					}
					.carImage{
						position: absolute;
						top: 0;
						left: 50%;
						transform: translateX(-50%);
						transition: all 0.3s ease-in-out;
						&.static{
							opacity: 1;
						}
						&.hover{
							opacity: 0;
						}

					}
					&:hover{
						.static{
							opacity: 0;
						}
						.hover{
							opacity: 1;
						}	
					}
				}
			}
			.colours{
				width: 100%;
				text-align: center;
				.colour{
					width: 75px;
					height: 75px;
					overflow: hidden;
					border-radius: 50%;
					display: inline-block;
					border: 2px solid $light-grey;
					margin: 10px 16px;
					transition: transform 0.3s ease-in-out;
					.left{
						width: 50%;
						height: 100%;
					}
					.right{
						width: 50%;
						height: 100%;
					}
					&.active{
						transform: scale(1.1);
					}
				}
			}
			.colourName{
				height: 44px;
				overflow: hidden;
				margin-top: 16px;
				.title{
					text-transform: capitalize;
					text-align: center;
				}
				.type{
					text-transform: capitalize;
					text-align: center;
				}
			}
		}
		.productModels{
			width: 100%;
			overflow: visible;
			margin-bottom: 64px;
			.productModelsHolder{
				.productModel{
					width: 100%;
					position: relative;
					.asset{
						position: relative;
						z-index: 9;
						img{
							width: 100%;
						}
					}
					.colours{
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						z-index: 5;
						.main{
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							height: 60%;
						}
						.secondary{
							position: absolute;
							bottom: 0;
							left: 0;
							width: 100%;
							height: 40%;
						}
					}
					.modelName{
						position: absolute;
						left: 30px;
						bottom: 30px;
						color: white;
						font-size: 18px;
						z-index: 9;
						span{
							font-family: $montserrat;
							font-weight: 800;
						}
					}
				}
			}
		}
		.downloadPDFButton{
			color: $dark-grey;
			border-bottom: 0px solid transparent;
			transition: 0.3s ease-in-out;
			margin-bottom: 3px;
			svg{
				margin-right: 15px;
			}
			a{
				color: $dark-grey;
				text-transform: uppercase;
			}
			&:hover{
				margin-bottom: 0px;
				border-bottom: 3px solid $dark-grey;
			}
		}
	}
}

.scrollarea {
	.scrollbar-container {
		position: absolute;
		background: none;
		opacity: 0.5;
		z-index: 9999;
		-webkit-transition: all 0.4s;
		transition: all 0.4s;
		&.vertical {
			width: 10px;
			height: 100%;
			right: 0;
			top: 0;
			.scrollbar {
				width: 8px;
				height: 20px;
				background: $dark-grey;
				margin-left: 1px;
			}
		}
		&.horizontal {
			width: 100%;
			height: 10px;
			left: 0;
			bottom: -10px;
			.scrollbar {
				width: 20px;
				height: 8px;
				background: $dark-grey;
				margin-top: 1px;
			}
		}
		&:hover,
		&.active {
			background: $light-grey;
			opacity: 1 !important;
		}
	}
}

.scrollarea {
	overflow: hidden;
	position: relative;
	width: 100%;
	height: 100%;
	.scrollarea-content {
		overflow: hidden;
		outline: 0;
	}
}

.scroll-red{
	.scrollarea {
		.scrollbar-container {
			&.vertical {
				.scrollbar {
					background: $honda-red;
				}
			}
			&.horizontal {
				width: 100%;
				height: 10px;
				left: 0;
				bottom: -10px;
				.scrollbar {
					width: 20px;
					height: 8px;
					background: $honda-red;
					margin-top: 1px;
				}
			}
			&:hover,
			&.active {
				background: #fa8b9c;
				opacity: 1 !important;
			}
		}
	}
}

@-webkit-keyframes pulse {
	0% {
		-webkit-box-shadow: 0 0 0 0 rgba(202, 202, 202, 0.4);
	}
	70% {
		-webkit-box-shadow: 0 0 0 10px rgba(202, 202, 202, 0);
	}
	100% {
		-webkit-box-shadow: 0 0 0 0 rgba(202, 202, 202, 0);
	}
}

@keyframes pulse {
	0% {
		-moz-box-shadow: 0 0 0 0 rgba(202, 202, 202, 0.4);
		box-shadow: 0 0 0 0 rgba(202, 202, 202, 0.4);
	}
	70% {
		-moz-box-shadow: 0 0 0 10px rgba(202, 202, 202, 0);
		box-shadow: 0 0 0 10px rgba(202, 202, 202, 0);
	}
	100% {
		-moz-box-shadow: 0 0 0 0 rgba(202, 202, 202, 0);
		box-shadow: 0 0 0 0 rgba(202, 202, 202, 0);
	}
}

.deals-grid {
	display: grid;
	column-gap: 20px;
	row-gap: 20px;
	// grid-template-columns: 33%;
	grid-template-rows: max-content;
	grid-template-columns: repeat(3, 1fr); /* sets 3 columns with equal width */ 

	@media(max-width: 1100px) {
		grid-template-columns: repeat(2, 1fr);
	}

	@media(max-width: 768px) {
		grid-template-columns: auto;
	}

	& > div { 
		margin: 0;

		& > .specialCard {
			margin: 0;
			min-height: 100%;
			display: flex;
			flex-direction: column;

			.specialWrapper {
				flex: 0 0 auto;
			}
			.specialFrom {
				flex: 0 0 auto;
			}
			.specialDescription {
				flex: 0 0 auto;
			}
			.specialButton {
				flex: 1 1 auto;
				display: inline-flex;
				align-items: flex-end;				
				gap: 10px;

				@media(max-width: 1150px) {
					flex-direction: column;
					justify-content: flex-end;
				}

				@media(max-width: 768px) {
					a {
						font-size: 15px;
					}
				}

				.hButton {
					margin: 0
				}

			}
		}
	}
}
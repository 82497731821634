.contactUs{
	margin-top: 55px;
	@media screen and (max-width: 600px) {
		margin-top: 0;
	}
	.customerCare{
		.link{
			a {
				color: $dark-grey;
				padding: 5px 0;
				display: block;
				svg{
					margin-right: 10px;
				}
			}
		}
	}
}
.galleryHolder {
    width: 100%;
    height: 80vh;
    // overflow: hidden;
    overflow: auto;
    padding-bottom: 8px;
    position: relative;
    margin-top: 32px;
    margin-bottom: 64px;
    //scrollbar////////
    scrollbar-color:#878787;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
        border-radius: 10px;
        height: 10px;
        width: 8px;
    }
    
    &::-webkit-scrollbar-thumb {
        background: #999;
        border-radius: 10px;
    }
    
    &::-webkit-scrollbar-track {
        border-radius: 10px;
    }
    .scrollarea {
        height: 100%;
        width: 100%;
        overflow: visible !important;
        position: relative;
        .scrollarea-content {
            overflow: hidden;
            height: 100%;
            outline: 0;
        }
        .scrollbar-container{
            z-index: 97 !important;
        }
        
    }
    //scrollbar////////
    
    .thumbnailGallery {
        height: 100%;
        white-space: nowrap;
        position: relative;
        .item {
            width: auto;
            height: 50%;
            float: left;
            border: 2px solid white;
            position: absolute;
            cursor: pointer;
            overflow: hidden;
            display: block;
            // display: -ms-grid; //breaks 5th image size in IE: "class two", also breaks image click/focus left alignment
            &.itemSize-two {
                height: 100%;
            }
            // &:first-child {
            // 	border-left: 0;
            // }
            .lazyLoad{
                height: 100%;
                position: relative;
                img {
                    transition: all 0.5s ease-in-out;
                    height: 100%;
                    min-width: 100%;
                    position: relative;
                    max-width: unset;
                    &:after {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        content: "";
                        background-color: rgba(
                            $color: #000000,
                            $alpha: 0.75
                        );
                        opacity: 0;
                    }
                    &:hover {
                        transform: scale(1.1);
                        &:after {
                            opacity: 1;
                        }
                    }
                }
                .video__overlay {
                    overflow: hidden;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    pointer-events: none;
                    svg {
                        width: 20%;
                        height: 20%;
                        color: white;
                        opacity: 0.5;
                    }
                }
            }
            img {
                transition: all 0.5s ease-in-out;
                height: 100%;
                width: auto;
                position: relative;
                max-width: unset;
                &:after {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    content: "";
                    background-color: rgba(
                        $color: #000000,
                        $alpha: 0.75
                    );
                    opacity: 0;
                }
                &:hover {
                    transform: scale(1.1);
                    &:after {
                        opacity: 1;
                    }
                }
            }
        }
        &:after {
            content: "";
            display: table;
            clear: both;
        }
    }
    .galleryCarousel {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 0%;
        overflow: hidden;
        z-index: 998;
        background-color: #000000;
        .asset {
            width: 100%;
            height: 100%;
            margin: 0 auto;
            text-align: center;
            position: absolute;
            top: 0;
            visibility: hidden;
            display: none;

            &.active, &.prev {
                display: block;
                visibility: visible;
            }

            &.active {
                z-index: 999;
            }

            &.prev {
                z-index: 998;
            }
            
            .lazyLoad {
                width: 100%;
                height: auto;
                position: absolute;
                left: 0;
                transform: translateY(-50%);
                top: 50%;
            }
            // &.exit{
            // 	opacity: 0 !important;
            // 	transform: translateX(0%) translateY(0px) translateZ(0px) !important;
            // }
        }

        .close {
            position: absolute;
            top: 10px;
            right: 15px;
            z-index: 9999;
            color: white;
			cursor: pointer;		
        }
        .navArrow {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            color: white;
            cursor: pointer;
            opacity: 0.8;
            transition: all 0.2s ease-in-out;
            padding: 20px;
            background-color: rgba($color: #000000, $alpha: 0.2);
            z-index: 9999;
            
            &.leftArrow {
                left: 5%;
            }
            &.rightArrow {
                right: 5%;
            }

            &:hover {
                opacity: 1;
                background-color: rgba($color: #000000, $alpha: 0.5);
            }
        }
    }
}
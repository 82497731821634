%wrapper_popUp {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
	&.active{
		z-index: 99999;
	}
	.shade{
		position: absolute;
		top: 0;
		left: 0;
		background-color: rgba($color: $dark-grey, $alpha: 0.7);
		width: 100%;
		height: 100%;
	}
	.error_modal {
		position: absolute;
		background-color: white;
		width: 70%;
		height: 35%;
		max-width: 1280px;
		left: 50%;
		top: 50%;
		overflow-y: auto;
		padding: 35px;
		.close{
			// padding: 20px 0;
			cursor: pointer;
			transition: 0.3s ease-in;
			&:hover{
				transform: rotate(90deg);
			}
		}
		.customContainer {
			width: 100%;
			border-radius: 5px;
			.content {
				.icon_wrapper {
					display: flex;
					justify-content: center;
					svg {
						color: $honda-red
					}
				}
				.message {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					text-align: center;
					.description {
						margin-top: 10px;
					}
				}
			}
		}
	}
}

.general_process {
	display: flex;
	justify-content: center;
	align-items: center;
}

.error_popUp {
	@extend %wrapper_popUp;
}

.fasttrack_wrapper {
    min-height: 70vh;
}


.accordion {
	.title {
		background-color: #f5f5f5;
		border-bottom: none;
		font-weight: 700;
		font-size: 16px;
		position: relative;
	}

	.contentHolder.open{
		padding: 16px 0;
		p{
			display: block;
			color: rgba(0,0,0, 0.85) !important;
		}
	}
}

.rmv-stepper{
	input::-webkit-outer-spin-button, input::-webkit-inner-spin-button{
		-webkit-appearance: none;
		margin: 0;
	}
	input[type=number]{
		-moz-appearance: textfield;
	}

	input[readonly]{
		background-color: #eaeaea;
	}
}

.input-perc-group{
	margin-bottom: 32px;
}

.input-perc-group.border-bottom{
	border-bottom: 1px solid $light-grey !important;
}

.input-perc-value{
	margin-top: 32px;
}

.m-auto{
	margin: 0 auto;
}

.perc-slider{
	padding: 32px 24px 32px 24px !important;
}

.total-val{
	padding-left: 25px;
	border-bottom: 1px solid red;
}

.input-perc-group.disabled{
	color: $light-grey !important;
}

.input-perc-group.disabled .total-val{
	border-bottom: 1px solid $light-grey !important;
}

.refferred__loader {

	position: relative;
	overflow: hidden;
	.clc__load {
		background: rgba(216, 216, 216, .2);;
		margin: 14px 0;
		border-radius: 5px;
	}

	.clc__load.text {
		width: 75%;
		height: 17.5px;
	}

	.clc__load.title {
		width: 35%;
		height: 23px;
		margin-bottom: 14px;
	}

	.loading__wrapper {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		animation: loading__wrapper__anim 1.3s infinite;
		.wrapper {
			width: 50%;
			height: 100%;
			background: rgba(255, 255, 255, .3);
			transform: skewX(-20deg);
		}

	}
}

@keyframes loading__wrapper__anim {
	0% { transform: translateX(-150%) }
	50% { transform: translateX(-60%) }
	100% { transform: translateX(150%) }
}

.dp_select{
	display: block;
	border: 1px solid $light-grey;
	text-align: center;
}

.dp_button{
	background-color: rgba(255, 255, 255, 0.9);
    padding: 5px;
    border: 1px solid $light-grey;
    border-radius: 2px;
    height: 3rem;
	transition: all 1sec ease-in-out;
}

.dp_button:focus{
	color: white;
	background-color: $honda-red;
}

.dp_header{
	margin: 10px;
}

.MuiSlider-markLabel{
	padding-top: 12px !important;
	font-size: 16px !important;
	font-weight: 900 !important;
}

.MuiSlider-mark{
	visibility: hidden;
}

.input-perc-group.disabled .MuiSlider-markLabel{
	visibility: hidden !important;
}

%searchResults {
	position: absolute;
	top: 120%;
	right: 0;
	width: 85%;
	background-color: white;
	z-index: 99;

	box-shadow: 0px 6px 20px rgba(30,30,30, 0.4);

	&.open {
		border: 1px solid $light-grey;
		padding: 10px 5px 10px 10px;
		&:before {
			opacity: 1;
		}
	}

	&.closed {
		display: none;
	}
	&:before {
		position: absolute;
		opacity: 0;
		top: -14px;
		// left: 35%;
		right: 10%; //prefer pointer right
		width: 25px;
		height: 25px;
		background-color: white;
		border-left: 1px solid $light-grey;
		border-top: 1px solid $light-grey;
		transform: rotate(45deg);
		content: "";
		transition: 0.1s ease-in-out;
	}
}

%searchResultsHolder {
	overflow-y: scroll;
	// height: 250px;
	z-index: 9999;
	&:after {
		content: "";
		display: table;
		clear: both;
	}
}

%result {
	width: 100%;
	padding: 5px 0.75rem;
	// float: left;
	cursor: pointer;
	position: relative;
	display: flex;
	.radio {
		width: 20px !important;
		height: 20px;
		margin-top: 7px;
		border-radius: 50px;
		border: 1px solid $light-grey;
		display: inline-block;
		margin-right: 15px;
		transition: 0.1s ease-in-out;
	}
	&:hover,
	&.active {
		.radio {
			background-color: $honda-red;
		}
	}
}

.preferredResultField {
	position: relative;
	.search_Results {
		width: 100%;
		@extend %searchResults;
		.search_Results_Holder {
			@extend %searchResultsHolder;
			._result {
				@extend %result;
			}
		}
	}
}

.generalInput {
	margin-bottom: 15px;
	h5 {
		margin-top: 0;
		margin-bottom: 5px;
		font-size: 15px;
	}
	.hint {
		margin-bottom: 5px;
		color: $light-grey;
		font-size: 12px;
	}
	input {
		width: 100%;
		border: 2px solid $light-grey;
		padding: 13px 20px;
		font-size: 18px;
		outline: 0;
		line-height: 25px;
	}

	.input__container {
		position: relative;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		& > div {
			position: absolute;
			padding-right: 10px;
			.loader {
				color: $honda-red;
			}
		}
	}
	&.valid {
		input {
			border: 2px solid $honda-green;
		}
	}
	&.invalid {
		input {
			border: 2px solid $honda-red;
			color: $dark-grey;
		}
	}
	.errorMessage {
		color: $honda-red;
		margin-top: 5px;
	}
}
.fc-white .generalInput {
	&.invalid {
		input {
			border: 2px solid $honda-red !important;
		}
	}
}

.generalTextArea {
	margin-bottom: 15px;
	h5 {
		margin-top: 0;
		margin-bottom: 5px;
		font-size: 15px;
	}
	.hint {
		margin-bottom: 5px;
		color: $light-grey;
		font-size: 12px;
	}
	textarea {
		width: 100%;
		border: 2px solid $light-grey;
		padding: 13px 20px;
		font-size: 18px;
		outline: 0;
		line-height: 25px;
		min-height: 150px;
	}
	&.valid {
		textarea {
			border: 2px solid $honda-green;
		}
	}
	&.invalid {
		textarea {
			border: 2px solid $honda-red;
			color: $dark-grey;
		}
	}
	.errorMessage {
		color: $honda-red;
		margin-top: 5px;
	}
}
.fc-white .generalTextArea {
	&.invalid {
		textarea {
			border: 2px solid $honda-red !important;
		}


	}
	.errorMessage {
		color: $honda-red !important
	}
}

.preferredDealerField {
	position: relative;
	h5 {
		margin-bottom: 35px;
		padding: 0 0.75rem;
	}
	.inputWrapper {
		.hint {
			margin-bottom: 5px;
			color: $light-grey;
			font-size: 12px;
		}
		.currentLocationHolder {
			float: left;
			padding: 0 0.75rem;
			.currentLocation {
				width: 100%;
				color: $honda-red;
				border: 2px solid $honda-red;
				padding: 20px;
				background-color: rgba($color: #ffffff, $alpha: 0.75);
				text-transform: uppercase;
				font-weight: 700;
				font-size: 18px;
				transition: 0.3s ease-in-out;
				cursor: pointer;
				text-align: left;
				svg {
					float: right;
				}
			}
			&.small {
				.currentLocation {
					position: relative;
					min-width: 64px;
					min-height: 64px;
					text-align: center;
					span {
						display: none;
					}
					svg {
						position: absolute;
						left: 50%;
						top: 50%;
						transform: translate(-50%, -50%);
					}
				}
			}
		}
		.searchLocationHolder {
			float: left;
			padding: 0 0.75rem;
			.searchLocation {
				width: 100%;
				border: 0;
				padding: 20px;
				font-size: 18px;
				text-align: left;
				outline: 0;
				border: 2px solid $light-grey;
			}
		}
		&:after {
			content: "";
			display: table;
			clear: both;
		}
	}
	.dealerResults {
		position: absolute;
		top: 120%;
		right: 0;
		// width: 85%;
		background-color: white;
		z-index: 99;
		&.open {
			border: 1px solid $light-grey;
			padding: 10px 15px;
			width: 100%;
			&:before {
				opacity: 1;
			}
		}
		&.closed {
			display: none;
		}
		.dealerResultsHolder {
			overflow: hidden;
			height: 100%;
			z-index: 9999;
			.dealer {
				width: 50%;
				padding: 5px 0.75rem;
				float: left;
				cursor: pointer;
				position: relative;
				.radio {
					width: 20px;
					height: 20px;
					border-radius: 50px;
					border: 1px solid $light-grey;
					display: inline-block;
					margin-right: 15px;
					transition: 0.1s ease-in-out;
				}
				.distance {
					position: absolute;
					right: 0.75rem;
				}
				&:hover,
				&.active {
					.radio {
						background-color: $honda-red;
					}
				}
			}
			&:after {
				content: "";
				display: table;
				clear: both;
			}
		}
		&:before {
			position: absolute;
			opacity: 0;
			top: -14px;
			// left: 35%;
			right: 10%; //prefer pointer right
			width: 25px;
			height: 25px;
			background-color: white;
			border-left: 1px solid $light-grey;
			border-top: 1px solid $light-grey;
			transform: rotate(45deg);
			content: "";
			transition: 0.1s ease-in-out;
		}
	}
	.selectedResult {
		padding: 0 0.75rem;
		font-family: $montserrat;
		font-weight: 700;
		margin-top: 15px;
		span.dealer_title {
			color: $honda-red;
		}
	}

	&.invalid {
		h5 {
			color: $honda-red;
		}
	}
	.errorMessage {
		padding: 0 0.75rem;
		color: $honda-red;
		margin-top: 5px;
	}
}
.fc-white .preferredDealerField {
	&.invalid {
		h5 {
			color: $honda-red !important;
		}
	}
	.errorMessage {
		color: $honda-red !important;
	}
}

.generalCheckbox {
	cursor: pointer;
	.block {
		min-width: 20px;
		min-height: 20px;
		border: 1px solid $light-grey;
		display: inline-block;
		margin-right: 15px;
		transition: 0.3s ease-in-out;
		position: relative;
		&:after {
			width: 0;
			height: 0;
			top: 50%;
			left: 50%;
			transform: translateX(-50%) translateY(-50%);
			border-radius: 50%;
			content: "";
			position: absolute;
			transition: 0.3s ease-in-out;
		}
	}
	&:last-child {
		border-bottom: 0;
	}
	&:hover {
		.block {
			&:after {
				background-color: $honda-red;
				width: 50%;
				height: 50%;
			}
		}
	}
	&.active {
		.block {
			border: 1px solid $dark-grey;
			&:after {
				background-color: $honda-red;
				width: 100%;
				height: 100%;
				border-radius: 0;
			}
		}
	}

	a {
		color: $dark-grey;
		margin-left: 3px;
		text-decoration: underline;
	}

	&.invalid {
		color: $honda-red;
		a {
			color: $honda-red;
		}
	}
}
.fc-white .generalCheckbox, .fc-grey .generalCheckbox {
	&.invalid {
		color: $honda-red !important;
		a {
			color: $honda-red !important;
		}
	}
}

.fc-grey {
	.generalCheckbox {
		a {
			color: $dark-grey;
		}
	}
}
.fc-white {
	.generalCheckbox {
		a {
			color: #f51638;
		}
	}
}

.generalDatePicker {
	margin-bottom: 15px;
	h5 {
		margin-top: 0;
		margin-bottom: 5px;
		font-size: 15px;
	}
	.hint {
		margin-bottom: 5px;
		color: $light-grey;
		font-size: 12px;
	}
	.react-datepicker-wrapper {
		width: 100%;
		.react-datepicker__input-container {
			width: 100%;
			input {
				width: 100%;
				border: 2px solid $light-grey;
				padding: 13px 20px;
				font-size: 18px;
				outline: 0;
				line-height: 25px;
			}
		}
	}
	.react-datepicker-popper {
		z-index: 9 !important;
	}
	.errorMessage {
		color: $honda-red;
		margin-top: 5px;
	}
	&.valid {
		input {
			border: 2px solid $honda-green !important;
		}
	}
	&.invalid {
		input {
			border: 2px solid $honda-red !important;
			color: $dark-grey;
		}
	}
	.errorMessage {
		color: $honda-red;
		margin-top: 5px;
	}
}

.fc-white .generalDatePicker {
	&.invalid {
		input {
			border: 2px solid $honda-red !important;
		}
	}
	.errorMessage {
		color: $honda-red !important;
	}
}

$border-radius: 20px;
$shadow-color: lighten($honda-red, 0.2);
$lighter-shadow: rgba($shadow-color, 0.2);
$transition: 0.3s all ease;

@mixin dragIndicator($property, $background, $z-index) {
	#{$property} {
		position: absolute;
		top: 0;
		z-index: $z-index;
		width: 0;
		height: 5px;
		border-radius: 5px;
		background: $background;
		#{$property}-drag {
			position: absolute;
			right: -50px;
			top: 50%;
			height: auto;
			width: 100px;
			padding: 10px 18px;
			background: $honda-red;
			transition: all 0.3s;
			transform: translateY(-50%);
			color: white;
			text-align: center;
			&:hover {
				box-shadow: 0 0 0 6px $lighter-shadow;
			}
		}
	}
}

.inputSliderHolder {
	h5 {
		margin-top: 0;
		margin-bottom: 5px;
		font-size: 15px;
	}
	.hint {
		margin-bottom: 5px;
		color: $light-grey;
		font-size: 12px;
	}
	.inputSlider {
		position: relative;
		margin: 0 auto;
		border-radius: 5px;
		cursor: pointer;
		margin-top: 48px;
		margin-bottom: 15px;
		width: 100%;
		height: 42px;
		.line {
			width: 100%;
			height: 5px;
			background: $light-grey;
		}
		@include dragIndicator(".min", $light-grey, 2);
		@include dragIndicator(".max", $shadow-color, 1);
	}
}
.pac-container {
	z-index: 99999 !important;
}

.introDescription {
	font-size: 22px;
	font-weight: 400;
	line-height: 2rem;

	p{
		margin: 25px 0;
	}
}

p {
	margin: 0;
}

.product-select-group {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	.product-select-item {
		flex: 1 1 0px; //CHANGE FOR MEDIA QUERY
		margin: 0 24px;
		overflow: none;
		min-width: 20%;
		&.hidden {
			visibility: hidden;
		}
		@media screen and (max-width: "760px"){
			flex: 0;
			min-width: 100%;
			margin: 0;
		}
		.productCard {
			border: 2px solid $light-grey;
			overflow: hidden;
			.productWrapper {
				.productTitle {
					z-index: 1 !important;
					h2 {
						font-size: 30px;
					}
				}
				.productImage {
					z-index: 2 !important;
				}
				&:hover {
					.productTitle {
						color: $light-grey;
					}
				}
			}
			.productDescription {
				color: $dark-grey;
			}
		}
		.productButton {
			margin: 0;
			.hButton {
				padding: 8px 24px;
				font-size: 10px;
			}
			.buttonRed {
				width: 100%;
				background-color: #f5163800;
				border: 2px solid #f51638;
				color: $honda-red;
			}
		}
	}



	.product-select-item:first-child {
		margin-left: 0;
	}
	.product-select-item:last-child {
		margin-right: 0;
	}
}

.group-buttons{
	margin: 24px 0 !important;
}
.messages{
	margin-top: 0.22%;
	margin-bottom: 0;
	transform: scale(1.01);
	.col{
		padding: 0 0.11%;
		// &:first-child{
		// 	padding-left: 0;
		// }
		// &:last-child{
		// 	padding-right: 0;
		// }
	}
}
.featuredCard{
	margin-top: 0.11%;
	margin-bottom: 0;
	.col{
		padding: 0.11% 0;
		&.left{
			padding-right: 0.11%;
		}
		&.right{
			padding-left: 0.11%;
		}
		a{
			display: grid;
		}
	}
}

.catShoppingTools{
	h4{
		margin-bottom: 30px;
	}
}

.customContainer.catShoppingTools {
	@media screen and (max-width: 1000px) {
		margin-top: 70px !important;
	}
}
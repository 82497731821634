.sceneWrapper {
	position: relative;
	width: 100%;
	margin: 0 auto;
	padding-top: 25px;
	margin: 0 0 16px 0;
	max-height: 550px !important;
	@media screen and (max-width: 720px) {
		max-height: 350px !important;
	}
	overflow: hidden;
	div {
		canvas {
			cursor: grab;
			@media screen and (max-width: 720px) {
				height: 180px !important;
			}
			height: 550px !important;
		}
	}

	.vt-nav-controllers {
		position: absolute;
		bottom: 0;
		margin: 0 auto;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 16px;
		.vt-arrow {
			opacity: 20%;
			@media screen and (max-width: 720px) {
				height: 50% !important;
			}
			transition: all 0.5 ease-in-out;
			img {
				width: 100%;
			}
		}
	}
}

.vt-loader {
	display: flex;
	justify-content: center;
	.loader svg {
		// text-align: center;
		color: #f51638;
	}
	span {
		width: 100px;
	}
}

.vtParent {
	position: relative;
	// @media screen and (max-width: 720px) {
	// 	max-height: auto !important;
	// }
	.cbTitle {
		font-size: 36px;
		text-align: center;
		margin: 40px 0;
		&.floatingHeader {
			margin-bottom: 64px;
		}
	}
	.cbDescription {
		text-align: center;
	}
	max-height: 950px;
	overflow: hidden;
	.lazyLoad {
		height: 100%;
		position: relative;
		cursor: pointer;
		@media screen and (min-width: 720px) {
			margin: 0 200px;
		}
		img {
			transition: all 0.5s ease-in-out;
			height: 100%;
			min-width: 100%;
			position: relative;
			max-width: unset;
			&:after {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				content: "";
				background-color: rgba($color: #000000, $alpha: 0.75);
				opacity: 0;
			}
		}

		.video__overlay {
			overflow: hidden;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			svg {
				width: 20%;
				height: 20%;
				color: white;
				opacity: 0.5;
			}
		}
	}
	img {
		transition: all 0.5s ease-in-out;
		height: 100%;
		width: auto;
		position: relative;
		max-width: unset;
		&:after {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			content: "";
			background-color: rgba($color: #000000, $alpha: 0.75);
			opacity: 0;
		}
	}

	.productColours {
		width: 100%;
		margin-bottom: 16px;
		// padding: 40px !important;
		.productColoursHolder {
			overflow: hidden;

			.switch-wrapper > input[type="checkbox"]:checked + .switch-true {
				transform: translateX(28px);
			}

			.switch-label {
				// float: left;
				line-height: 2.5rem;
				margin: 0 10px;
				font-size: 0.8rem;
				color: rgba(0, 0, 0, 0.87);
			}

			.switch-wrapper {
				border-radius: 20px;
				border: 2px solid $honda-red;
				cursor: pointer;
				font-size: 20px;

				.switch-true{
					color: #fff;
					display: inline-block;
					padding-left: 0;
					width: 55px;
					transition: 100ms linear;
					&::before {
						background: gray !important;						
						content: "";
						top: 5px;
						left: 5px;
						height: 18px;
						width: 18px;
						z-index: 0;
						border: 0;
						transform: rotate(0);							
						border-radius: 50%;
					}				
				}
				.switch-false{
					color: #fff;
					display: inline-block;
					padding-left: 0;
					width: 55px;
					transition: 100ms linear;
					&::before {
						background: $honda-red !important;						
						content: "";
						top: 5px;
						left: 5px;
						height: 18px;
						width: 18px;
						z-index: 0;
						border: 0;
						transform: rotate(0);							
						border-radius: 50%;
					}				
				}
			}
		}
		@media screen and (max-width: 720px) {
			padding: 20px !important;
		}
		.colourHolder {
			width: 100%;
			margin: 0 auto;
			position: relative;
			height: 0;
			.car {
				width: 100%;
				position: absolute;
				top: 0;
				height: auto;
				text-align: center;
				padding: 0 12.5%;
				.placeholder {
					opacity: 0;
				}
				.carImage {
					position: absolute;
					top: 0;
					left: 50%;
					transform: translateX(-50%);
					transition: all 0.3s ease-in-out;
					&.static {
						opacity: 1;
					}
					&.hover {
						opacity: 0;
					}
				}
				&:hover {
					.static {
						opacity: 0;
					}
					.hover {
						opacity: 1;
					}
				}
			}
		}
		.colours {
			width: 100%;
			text-align: center;
			.colour {
				width: 75px;
				height: 75px;
				overflow: hidden;
				border-radius: 50%;
				display: inline-block;
				border: 2px solid $light-grey;
				margin: 10px 16px;
				transition: transform 0.3s ease-in-out;
				.left {
					width: 50%;
					height: 100%;
				}
				.right {
					width: 50%;
					height: 100%;
				}
				&.active {
					transform: scale(1.1);
				}
			}
		}
		.colourName {
			height: 44px;
			overflow: hidden;
			margin-top: 16px;
			.title {
				text-transform: capitalize;
				text-align: center;
			}
			.type {
				text-transform: capitalize;
				text-align: center;
			}
		}
	}
}

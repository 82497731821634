// TODO : Make sure the imageHolder does not exceed when the image exceed a specific size.

@media only screen and (max-width: 600px) {
	.buttonWhite {
		background-color: $honda-red !important;
		border-color: $honda-red !important;
	}
}

.rowSlider {
	position: relative;
	// height: 100%;
	.slideHolder {
		margin: 0 auto;
		transition: height 0.2s ease-in-out;
		overflow: hidden;
		.slide {
			float: left;
			overflow: hidden;
		}
	}
	.slideWrapper {
		width: 100%;
		height: 100%;
		overflow: hidden;
	}
	.navItem {
		top: 50%;
		transform: translateY(-50%);
		position: absolute;
		width: 50px;
		text-align: center;
		justify-content: center;
		transition: 0.3s ease-in-out;
		z-index: 100;
		padding: 20px 0px;
		&:hover {
			background-color: rgba($color: #000000, $alpha: 0.8);
			color: white;
		}
		&.navLeft {
			left: -5%;
		}
		&.navRight {
			right: -5%;
		}
	}
	.contain {
		.navLeft {
			left: 2.5%;
		}
		.navRight {
			right: 2.5%;
		}
	}
	.bullets {
		text-align: left;
		padding: 20px 0;
		position: absolute;
		left: 3%;
		bottom: 0;
		z-index: 100;
		// white-space: nowrap;
		// @media (max-width: 880px) and (min-width:601px) {
		// 	bottom: -13%;
		// }

		&.left {
			left: 3%;
		}

		&.center {
			left: 50%;
			transform: translateX(-50%);
		}

		span {
			width: 25px;
			height: 25px;
			border-radius: 50%;
			border: 2px solid white;
			display: inline-block;
			margin: 5px;
			background-color: white;
			transition: 0.3s ease-in-out;
			&.active {
				background-color: $honda-red !important;
			}
		}
	}
}

//New product slider
.slider_wrapper {
	position: relative;
	height: auto;
	// overflow: hidden;
	.control {
		position: absolute;
		top: 50%;
		color: #fff;
		z-index: 996;
		padding: 0px 15px;
		transition: all 0.5s ease;
		transform: translateY(-50%);
	}
	.left {
		left: 0;
	}
	.right {
		right: 0;
	}
}

.slider {
	position: relative;
	display: flex;
	align-items: center;
	overflow: hidden;
	height: 100%;
	ul.timelineSteps {
		position: absolute;
		bottom: 5%;
		display: flex;
		align-items: center;
		justify-content: space-evenly;
		width: 100%;
		height: 50px;
		font-family: $montserrat;
		font-weight: 500;
		.item {
			height: 100%;
			text-align: center;
			// line-height: 2em;
			margin: 0;
			display: inline-block;
			color: white;
			position: relative;
			width: 100%;
			cursor: pointer;
			.icon {
				position: absolute;
				width: 5em;
				height: 5em;
				// line-height: 5em;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				border: 1px solid white;
				border-radius: 100%;
				background-color: rgba($color: #000000, $alpha: 0.1);

				svg {
					width: 50%;
					height: 100%;
				}
				div {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					& p {
						width: max-content; //single line, uncomment for multiline
						color: #ffffff;
						font-size: smaller;
					}
				}
				&:after {
					width: 6.3em;
					height: 6.3em;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					border: 1px solid white;
					border-radius: 100%;
					position: absolute;
					content: "";
					opacity: 0;
					transition: opacity 0.3s ease-in-out;
				}
			}
			&:after {
				content: "";
				position: absolute;
				top: 50%;
				background-color: white;
				height: 1px;
				width: calc(100% - 5em + 1px);
				left: calc(50% + 2.5em);
			}
			&:first-child::before {
				display: none;
			}
			&:last-child::after {
				display: none;
			}

			&.active {
				.icon {
					&:after {
						opacity: 1;
						box-shadow: 0 0 0 rgba(202, 202, 202, 0.8);
						animation: pulse 1.5s infinite;
					}
				}
			}
		}
	}
}

.slider-item {
	display: block;
	position: relative;
	min-width: 100%;
	max-height: 100%;
	transition: 0.5s;
	overflow: hidden;
	.slide-item__wrapper {
		display: flex;
		flex-wrap: wrap;
		width: auto;
		max-height: 100%;
		overflow: hidden;
		.lazyLoad {
			display: inline-block;
			position: relative;
			overflow: hidden;
			width: auto;
			max-height: 100%;
			img.lazyLoadImg {
				display: inline-block;
				height: auto;
				min-width: 105%;
				max-width: 110%;
			}
		}
	}
}

// TODO: white, lightGray, darkGray, charcoal

.timelineSteps.turquoise {
	.item {
		color: turquoise !important;
		.icon {
			border: 1px solid turquoise !important;
			span p {
				color: white;
			}
			&:after {
				border: 1px solid turquoise !important;
			}
		}
		&:after {
			background-color: turquoise !important;
		}
	}
	.line {
		span {
			background: turquoise !important;
		}
	}
}

.recallRedirect{
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
}

.recall{
	.link{
		a {
			color: $dark-grey;
			padding: 5px 0;
			display: block;
			svg{
				margin-right: 10px;
			}
		}
	}
	.messages-container{
		margin: 0 0 15px 0;

		.recall-message {
			text-transform: uppercase;
			margin: 0 0 15px 0;
		}
	}
	.table {
		table{
			table-layout: fixed;
		}
		pre {
			font-family: "Lato", sans-serif;
			margin: 0;
		}
		th {
			padding-left: 16px;
			font-size: 16px;
			color: #333333;
			font-weight: bold;
		}

		td {
			padding-left: 16px;
			font-size: 12px;
			color: #333333;
		}
		td,
		th {
			border-right: 1px solid #f4f4f4;
			border-left: 1px solid #f4f4f4;
			border-bottom: 1px solid #f4f4f4;
		}

		thead {
			tr {
				background: #f5f5f5;
				border-bottom: #f5f5f5;
				padding-left: 16px;
				td{
					font-size: 16px;
					color: #333333;
					font-weight: bold;
				}
			}
		}
	}

	button{
		border-width: 4px !important;
		svg{
			margin-right: 15px;
		}
	}
}
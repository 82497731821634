
@media (max-width: 880px) and (min-width:601px) {
	.bullets {
		bottom: -13% !important;
	}
}

@media(max-width: 1400px) {
	h1{
		font-size: 45px;
	}
	.specialCard {
		.specialTitle {
			h2{
				
			}
		}
		.specialFrom{
			
		}
		.specialButton{
			a{
				font-size: 13px;
			}
		}
	}
	
	.banner {
		.productBannerCopyHolder {
			.productBannerCopy {
				.copy {
					h1, h2{
						font-size: 54px;
						line-height: 64px;
					}
				}
				.buttons{
					margin-top: 24px;
				}
			}
		}
	}
	.productBuilderHolder {
		.productBuilder {
			.galleryHolder{
				height: 70vh;
			}
			.productFeatures {
				.productFeaturesHolder{
					height: 65vh;
					.feature{
						.lazyLoad {
							height: 100%;
							.lazyLoadImg{
								width: auto;
								height: 100%;
							}
						}
						.contentHolder{
							.content{
								padding: 2.5% 0;
							}
						}
					}
					.featureSteps{
						left: 3%;
					}
				}
			}
		}
	}
	// .selectWrapper {
	// 	.selectHolder {
	// 		& > .title {
	// 			font-size: 14px;
	// 			padding: 10px 0;
	// 			text-indent: 10px;
	// 		}
	// 	}  misaligns title input div in forms @1400 width
	// }
	.sideNavHolder{
		left: 0.5%;
		bottom: 1%;
		z-index: 996;
	}
	.newsHolder {
		.featuredNewsCard {
			.newsFeatured {
				.title{
					font-size: 28px;
				}.category{
					font-size: 20px;
				}
			}
		}
	}
}

@media(max-width: 1280px) {
	.banner{
		.productBannerCopyHolder{
			.productBannerCopy{
				width: 85%;
				.copy{
					h1{
						font-size: 50px;
						line-height: 60px;
					}
					h6{
						margin-bottom: 18px;
					}
				}
				.buttons{
					a{
						font-size: 13px;
						margin-right: 20px;
					}
				}
			}
		}
	}
	.rowSlider {
		.bullets{
			bottom: -3%;
			span{
				width: 20px;
				height: 20px;
			}
		}
	}
	.shoppingTools {
		.toolHolder {
			.tool{
				width: 150px;
				height: 150px;
				svg{
					width: .75em;
				}
			}
		}
	}
	.linkCard {
		.imageHolder {
			.content {
				.title{
					font-size: 22px;
					line-height: 28px;
					bottom: 10px;
					left: 32px;
				}
			}
		}
	}
	footer {
		a{
			margin: 5px 0;
		}
	}
	.generalNav {
		.nav-extended {
			.navBlock {
				.navBlockItem{
					margin-right: 10px;
					a{
						padding: 0 10px;
						font-size: 13px;
					}
				}
			}
		}
	}

	.preferredDealerField {
		.inputWrapper {
			.currentLocationHolder {
				.currentLocation{
					font-size: 16px;
				}
			}
		}
	}
	.specialCard {
		.specialTitle {
			h2{
				font-size: 32px;
			}
		}
		.specialFrom{
			font-size: 17px;
		}
		.specialButton{
			a{
				font-size: 10px;
				padding: 12px 10px;
			}
		}
	}
}

@media(max-width: 1100px) {
	.banner{
		.productBannerCopyHolder{
			.productBannerCopy{
				.copy{
					h1{
						font-size: 40px;
						line-height: 50px;
						margin: 0;
					}
					h6{
						margin-bottom: 5px;
						p{
							margin: 5px 0;
						}
					}
				}
				.buttons{
					margin-top: 15px;
					a{
						font-size: 13px;
						margin-right: 20px;
					}
				}
			}
		}
	}
	.preferredDealerField {
		.inputWrapper {
			.searchLocationHolder {
				.searchLocation{
					padding: 18px;
    				font-size: 16px;
				}
			}
			.currentLocationHolder {
				.currentLocation{
					font-size: 12px;
				}
			}
		}
	}
	.specialCard {
		.specialTitle {
			h2{
				font-size: 22px;
			}
		}
	}
	.specialCard {
		.specialButton{
			.hButton{
				width: 100%;
				
				&:last-child{
					margin-bottom: 0;
				}
			}
		}
	}
	.bookATestDrivePopUp{
		.popUp{
			width: 100%;
			height: 100%;
		}
	}
}

@media(max-width: 1050px) {
	.shoppingTools {
		.toolHolder {
			.tool{
				.hover{
					top: 25%;
    				left: 0;
				}
			}
		}
	}
}

@media(min-width: 1001px) {
    // Cars Homepage :: Deskstop
    .categoryBuilderHolder {
        .productMobileSlider {
            display: none;
        }
    }
    .largeFooterMobile {
        display: none;
    }

     //productModels and pricing
     .productModels {
        .mobileSliderr {
            display: none;
        }
    }
    // Car List Homepage Media Queries
    .listCarProduct {
        padding: 20px 0;
        .row {
            display: flex;
            flex-flow: wrap;
            .col:nth-child(3n+1) {
                .productCard {
                    &::before {
                        content: none;
                    }
                }
            }
            .col {
                margin-left: 0px;//mod for IE11, was unset
            }
        }
    }

    // Find A Dealer 
    .findADealer {
        .banner {
            .overlay {
                position: absolute;
                width: 100%;
                height: 100%;
                z-index: 1;
                background: #0000005c;
            }
            img {
                height: 100%;
                width: 100%;
            }
            .location {
                z-index: 2;
                .hiddenH1 {
                    text-align: center;
                    visibility:visible;
                    height: unset;
                    margin-bottom: 25px;
                }
                h2 {
                    margin: 25px 0;
                }
			}
			&.small{
				img {
					height: auto;
					width: 100% !important;
				}
			}
        }
    }

    // List Special Offers
    .listSpecialOffers {
        .specialCard {
            
            .specialDescription {
                p {
                    margin: 10px 0;
                }
            }
        }
    }

    // Homepage
    .homePage {
        .bannerCarousel {
            .rowSlider {
				.bullets {
                    left: 4rem;
                    bottom: -1rem;
                }
            }
        }
    }
}
.findADealer{
	width: 100%;
	height: 100vh;
	position: relative;
	overflow: hidden;
	.banner{
		width: 100%;
		height: 100%;
		overflow: hidden;
		.lazyLoad{
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			.placeholder{
				width: 100%;
    			height: 100%;
			}
		}
		img{
			width: 100%;
			height: auto;
			transform: translateX(-50%) translateY(-50%);
			position: absolute;
			top: 50%;
			left: 50%;

		}
		.bannerTitle{
			position: absolute;
			top: 15%;
			width: 100%;
			text-align: center;
			font-size: 40px;
			color: #fff;
			transition: 0.3s ease-in-out;
			z-index: 5;
		}
		.introHolder{
			position: absolute;
			bottom: 10%;
			left: 10%;
			width: 80%;
			text-align: center;
			font-size: 40px;
			color: #fff;
			transition: 0.3s ease-in-out;
			z-index: 5;
		}
		.location{
			position: absolute;
			transform: translateX(-50%) translateY(-50%);
			top: 50%;
			left: 50%;
			width: 50%;
			max-width: 500px;
			text-align: center;
			color: white;
			z-index: 5;
			.currentLocation{
				width: 100%;
				color: $honda-red;
				border: 2px solid $honda-red;
				padding: 20px;
				background-color: rgba($color: #FFFFFF, $alpha: 0.75);
				text-transform: uppercase;
				font-weight: 700;
				font-size: 18px;
				transition: 0.3s ease-in-out;
				cursor: pointer;
				svg{
					margin-right: 32px;
				}
			}
			h2{
				transition: 0.3s ease-in-out;
			}
			.searchLocation{
				width: 100%;
				border: 0;
				padding: 20px;
				font-size: 18px;
				text-align: center;
				outline: 0;
			}
		}
		&:before{
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba($color: #000000, $alpha: 0.4);
			z-index: 1;
		}
		&.small{
			.bannerTitle{
				opacity: 0;
				height: 0;
				overflow: hidden;
				padding: 0;
				margin: 0;
				max-height: 0;
				border: 0;
			}
			.location{
				.currentLocation{
					opacity: 0;
					height: 0;
					overflow: hidden;
					padding: 0;
					margin: 0;
					max-height: 0;
					border: 0;
				}
				h2{
					opacity: 0;
					height: 0;
					overflow: hidden;
					margin: 0;
				}
			}
			.introHolder{
				opacity: 0;
				height: 0;
				overflow: hidden;
				margin: 0;
			}
		}
	}
	.results{
		width: 100%;
		overflow: hidden;
		.row{
			height: 100%;
			.col{
				height: 100%;

				&.dealersHolder{
					background-color: $light-grey;
					padding: 0 35px;
					overflow-y: auto;
					.dealer{
						background-color: white;
						padding: 15px;
						margin-bottom: 15px;
						.intro{
							.location{
								float: left;
								font-size: 25px;
								cursor: pointer;
							}
							.titleHolder{
								float: left;
								padding-left: 15px;
								padding-top: 5px;
								width: 93%;
								.title{
									margin: 0;
								}
								.distance{
									font-family: $montserrat;
									font-weight: bold;
									font-size: 12px;
								}
								.address{
									font-size: 11px;
								}
							}
							&:after{
								clear: both;
								display: table;
								content: "";
							}
						}
						.info{
							margin-top: 15px;
							.row{
								.col{
									font-size: 12px;
									margin: 5px 0px;
									a{
										color: black;
									}
									svg{
										margin-right: 15px;
										font-size: 18px;
									}
								}
							}
						}
						.buttons{														
							padding-top: 15px;
							border-top : 1px solid $light-grey;
						}
						

						&.active{
							.intro{
								.location{
									color: $honda-red;
								}
							}
						}
					}
				}

				&.mapHolder{
					padding: 0;
				}
				#map {
					height: 100%;
				}
			}
		}
	}	
}
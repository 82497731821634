.listCarProduct {
    .productCard {
        position: relative;
        &:before {
            content: '';
            position: absolute;
            left: -15px;
            top: 0;
            height: 100%;
            background-color: $light-grey;
            width: 2px;

        }
        .productHeading {
            h2 {
                font-size: 32px;
                margin: 0;
                color: $black;
            }
        }
        .productDescription {
            p {
                font-size: 16px;
            }
        }
        .line {
            width: 64px;
            height: 5px;
            margin: 15px 0;
            background-color: $honda-red;
        }
    }
}
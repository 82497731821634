
.modal-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
	&.open {
		z-index: 99999;
	}
	.shade {
		position: absolute;
		top: 0;
		left: 0;
		background-color: rgba($color: $dark-grey, $alpha: 0.7);
		width: 100%;
		height: 100%;
	}
	.popUp {
		position: absolute;
		background-color: white;
		width: 80%;
		height: 80%;
		max-width: 1280px;
		left: 50%;
		top: 50%;
		overflow-y: auto;
		padding: 35px;
		.close {
			// padding: 20px 0;
			cursor: pointer;
			transition: 0.3s ease-in;
			&:hover {
				transform: rotate(90deg);
			}
		}
		.formLoader {
			height: 100%;
			position: relative;
			span {
				font-size: 80px;
				margin: 0;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}
		.customContainer {
			width: 100%;
			.intro {
				margin-bottom: 35px;
				h2.left {
					margin: 0;
				}
			}
			.productSelect {
				display: flex;
				flex-wrap: wrap;
				margin-bottom: 55px;
				.productHolder {
					width: 100%;
					border: 2px solid $light-grey;
					.productSelectTitle {
						background-color: $dark-grey;
						width: 100%;
						min-height: 100% !important;
						border: 2px solid rgba($color: $dark-grey, $alpha: 0.7);
						h5 {
							margin: 0;
							border: none;
							color: white !important;
							padding: 10px 0 10px 10px;
						}
					}
					.img {
						display: block;
						width: 100%;
						text-align: center;
						img {
							width: 100%;
							height: auto;
							max-width: 400px;
						}
					}
					.models {
						width: 100%;
						.model {
							border-top: 2px solid $light-grey;
							padding: 15px;
							text-transform: uppercase;
							font-weight: 700;
							text-decoration: underline;
							text-decoration-color: white;
							transition: 0.3s ease-in;
							cursor: pointer;
							&:hover,
							&.active {
								text-decoration-color: $honda-red;
								background-color: #f3f3f3;
							}
						}
					}
				}
				.childrenHolder {
					display: flex;
					.special {
						width: 100%;
						border: 2px solid $light-grey;
						padding: 15px;
						.title {
							color: $dark-grey;
							h2 {
								margin: 0;
							}
						}
						.from {
							margin-top: 15px;
							font-size: 20px;
							color: $honda-red;
							font-weight: 700;
							font-family: $montserrat;
						}
						.description {
							margin-top: 15px;
							color: $dark-grey;
							p {
								margin: 0;
								strong {
									font-weight: 700;
								}
							}
						}
					}
				}
			}
		}
	}
}
@media (max-width: 650px) {
	.campaignBuilderHolder {
		.campaignBuilder {
			.copyWithImage {
				.copyHolder {
					position: static;
					transform: unset;
					width: 100%;
					text-align: center;
					padding: 32px 5%;
					.cbTitle {
						text-align: center;
					}
				}
			}
			.headerWithCopy {
				padding-top: 32px;
			}
			.productColours {
				.productColoursHolder {
					.car {
						padding: 0; // to increase image size, need to test tablet
					}
				}
			}
			.countdownTimer {
				.unitWrapper {
					padding: 5px;
					margin: 5px;
					.unit {
						font-size: 2rem;
					}
					.description {
						font-size: 0.7rem;
					}
				}
			}

			.productFeatures {
				.productFeaturesHolder {
					.feature {
						.contentHolder {
							.content {
								.copy {
									font-size: 17px;
								}
							}
						}
					}
				}
			}
		}
	}
}

@media (min-width: 601px) and (max-width: 999px) {
	//Tablet sizes
	.formBuilder {
		.row .col.m6 {
			width: 100%;
		}
	}
}

@media(max-width: 991px) {
	// subNavBar :: Cars Detailpage & Campaigns
	.navBuilderHolder {
		.navBuilder {
			overflow-x: auto; //make subnav scrollable, should maybe indicate the functionality somehow (arrow/... icon?)
			overflow-y: hidden;
			white-space: nowrap; //extend subnav off mobile screen
		}
	}
}

@media (max-width: 1024px) {
    /* Cars/MC "Find a Honda" slider for mobile only */
	.customContainer {
		.productMobileSlider {
            .contain {
                .navLeft {
                    left: 0 !important;
                }
                .navRight {
                    right: 0 !important;
                }
            }
			.productImage {
				max-width: 80%;
				margin: 0 auto;
			}
		}
	}
}
@media (max-width: 1000px) {
	.footer_social_icons {
		margin: 0 auto 25px auto !important;
		.footer_social_icon {
			margin-right: 25px !important;
			margin-left: 25px !important;
		}
	}
}

@media (max-width: 600px) {
	h1 {
		font-size: 30px;
	}
	h2 {
		font-size: 25px;
	}
	h3 {
		font-size: 20px;
		padding: 0 0 10px;
	}
	.customContainer {
		width: 95%;
	}

	.sidenav-trigger {
		line-height: 55px;
	}

	// Global Banner
	.banner {
		.imageHolder {
			.countdownTimerHolder {
				.countdownTimer {
					top: -33px;
					.unitWrapper {
						padding: 10px 5px;
						margin: 5px;
						.unit {
							width: 60px;
						}
						.description {
							font-size: 1.5rem;
						}
					}
				}
			}
		}
		.productBannerCopyHolder {
			.productBannerCopy {
				padding: 0 10px;
				.copy {
					h1 {
						line-height: 40px;
						margin-top: 10px;
					}
				}
				.buttons {
					a {
						width: 100%;
						margin: 5px 0%;
					}
				}
			}
		}
	}
	.smallBanner {
		.productBannerCopyHolder {
			position: unset;
			padding-top: 2rem;
			padding-bottom: 2rem; //because when there are no buttons to normally add padding ie news/BATD
			.productBannerCopy {
				position: unset;
				transform: none;
				width: 100%;
				.copy {
					padding: 0 20px !important;
					width: 100%;
					h1 {
						color: $black;
						font-size: 30px;
						line-height: 48px;
					}
					h6 {
						color: $black;
					}
				}
			}
		}
		.imageHolder {
			.overlay {
				display: none;
			}
		}
	}
	//  Cars Homepage
	.categoryBuilderHolder {
		// messages
		.messages {
			margin-left: 10px;
			margin-right: 10px;
			margin-bottom: 0;
		}

		// Feature Card
		.featuredCard {
			// margin: 15px 10px;
			.col {
				padding: 2px 0;
				&:first-child {
					padding-right: 0;
				}
				.linkCard {
					.imageHolder {
						.content {
							.title {
								font-size: 18px;
								left: 15px;
								bottom: 0;
								line-height: unset;
								.line {
									margin-bottom: 5px;
									height: 3px;
								}
							}
						}
					}
				}
			}
		}
		// Carousel Slider
		.rowSlider {
			.navItem {
				&.navLeft {
					left: -2% !important;
				}
				&.navRight {
					right: -2% !important;
				}
			}
			.productImage {
				padding: 0 10px;
			}
		}
	}
	// subNavBar :: Cars Detailpage
	.navBuilderHolder {
		height: auto !important;
		.navBuilder {
			overflow-x: auto; //make subnav scrollable, should maybe indicate the functionality somehow (arrow/... icon?)
			overflow-y: hidden;
			white-space: nowrap; //extend subnav off mobile screen
		}
	}
	// Cars Detailpage
	.productBuilderHolder {
		.productBuilder {
			.cbTitle {
				font-size: 28px;
				margin: 15px 0;
			}
			// Gallery
			.galleryHolder {
				.scrollarea {
					.scrollarea-content.thumbnailGallery {
						.item {
							padding: 2px;
							border: 0;
							&:nth-child(2),
							&:first-child {
								padding-left: 0;
							}
						}
					}
				}
				.galleryCarousel {
					position: fixed;
					z-index: 9999999999999;
					.close {
						top: 95px !important;
					}
				}
				.thumbnailGallery {
					.item {
						&.itemSize-two {
							height: 50%;
						}
					}
				}
			}
			// Product Colors
			.productColours {
				padding: 40px 0;
				.cbTitle {
					margin: 30px 0 !important;
				}
				.colours {
					.colour {
						width: 40px;
						height: 40px;
						margin: 15px 5px;
					}
				}
				.productColoursHolder {
					.colourHolder {
						width: 100% !important;
						height: 220px !important;
					}
					.car {
						padding: 0; // to increase image size, need to test tablet
					}
				}
			}
			// Product Features
			.productFeatures {
				.productFeaturesHolder {
					.feature {
						.contentHolder {
							// h3{
							//     padding: 0 0 10px ;
							// }
							.content {
								width: 71% !important;
								margin-left: 22% !important; //was 25%
								padding: 8% 0;
								.copy {
									width: 100% !important;
									font-size: 17px; //was 14
								}
							}
						}
					}
					.featureImage {
						height: 100% !important;
					}
					.featureSteps {
						left: 0 !important;
						.item {
							width: 3.1em !important;
							&:before {
								left: 38% !important;
								height: calc(50% - 1.25em) !important;
							}
							&:after {
								left: 38% !important;
								width: 1px;
								height: calc(50% - 1.25em) !important;
							}
							.icon {
								width: 2.5em !important;
								height: 2.5em !important;
								top: 50% !important;
								& .custom-icon {
									height: 2.2em !important;
									padding: 5px;
								}
							}
							// &.item:nth-child(4) {
							//     display: none;
							// }
							// &.item:nth-child(5) {
							//     display: none;
							// }
							// &.item:nth-child(6) {
							//     display: none;
							// }
							&.active {
								.icon {
									&:after {
										width: 2.6em;
										height: 2.6em;
										border-width: 1px;
									}
								}
							}
						}
					}
				}
			}
			//Cars Specifications
			.specificationSection {
				.cbTitle {
					margin: 30px 0;
				}
				.selectTwo {
					display: none;
				}
				.selectThree {
					display: none;
				}
			}

			// Product Models
			.productModels {
				.rowSlider {
					.navItem.navLeft {
						display: none;
					}
					.navItem.navRight {
						display: none;
					}
				}
				.cbTitle {
					margin: 30px 0 !important;
				}
				.productModelsHolder {
					.productModel {
						.modelName {
							top: 10px;
							left: 10px;
							bottom: unset;
						}
					}
				}
			}
		}
	}
	// listCarProduct
	.listCarProduct {
		.row {
			.col:nth-child(1) {
				.productCard {
					&:before {
						content: none;
					}
				}
			}
		}
		// Product card
		.productCard {
			position: relative;
			&:before {
				content: "";
				position: absolute;
				top: 0;
				width: 100%;
				background-color: $light-grey;
				height: 2px;
			}
			.productHeading {
				h2 {
					font-size: 18px;
				}
			}
		}
	}
	.findADealer {
		overflow-y: visible;
		min-height: 100%;
		height: auto;

		.banner {
			.bannerTitle {
				margin: 16px 0;
				font-size: 32px !important;
			}
		}
		.introHolder {
			bottom: 4% !important;
			.introTitle {
				font-size: 18px;
			}
			.introDescription {
				font-size: 14px;
			}
		}

		.results {
			overflow: hidden;
			.row {
				height: auto;
				margin-bottom: 0;
				.col {
					height: auto;
					#map {
						height: 300px;
					}
					&.dealersHolder {
						padding: 0 15px;
					}
				}
			}
		}
	}
	// Finda a Dealer
	.showLocationVisible {
		.banner {
			height: 35vh !important;
			.location {
				transform: translateX(-50%) translateY(-30%);
				input {
					font-size: 16px !important;
				}
			}
		}
	}
	// bookATestDrive
	.bookATestDrive {
		width: 88%;
		.mt32px {
			margin-top: 0 !important;
		}
		.form {
			&.open {
				.carDetailsHeading {
					margin-top: 25px;
				}
				.preferredDealerField {
					.dealerResults {
						width: 100%;
						.dealerResultsHolder {
							.dealer {
								width: 100% !important;
							}
						}
					}
				}
			}
		}

		.preferredDealerField {
			margin-top: 0 !important;
			.dealerResults {
				width: 100%;
				.dealerResultsHolder {
					.dealer {
						width: 100% !important;
					}
				}
			}
		}
	}
	// Request a Partpage
	.bookAService,
	.requestAPart {
		.formHolder {
			.introDescription {
				p {
					font-size: 14px;
				}
			}
			.preferredDealerField {
				.dealerResults {
					width: 100%;
					.dealerResultsHolder {
						.dealer {
							width: 100%;
						}
					}
				}
			}
		}
	}
	// Homepage
	.homePage {
		.shoppingTools {
			justify-content: center !important;
			.col {
				padding: 0 1.2rem !important;
				.toolHolder {
					.tool {
						.hover {
							top: -28% !important;
							left: 25% !important;
						}
					}
				}
			}
		}
		.bannerCarousel {
			.rowSlider {
				.slideWrapper {
					.slideHolder {
						height: auto !important;
					}
				}
				.contain {
					&.white-text {
						display: none;
					}
				}
				.bullets {
					bottom: -35px;
					left: 5px;
					span {
						width: 18px;
						height: 18px;
					}
				}
			}
			.carouselSlider {
				.exploreClick {
					bottom: unset;
					position: relative;
					margin-bottom: 55px;
					padding: 5px 10px;
				}
			}
			.thumbnails {
				margin-top: 64px;
				.carouselThumbnail {
					width: 100%;
					border: 0;
					margin-top: 5px;
				}
			}
		}
		.thumbnails {
			.carouselThumbnail {
				.linkCard {
					.imageHolder {
						.content {
							.title {
								left: 15px;
								bottom: 0;
								font-size: 18px;
								.line {
									height: 3px;
									margin: 0;
								}
							}
						}
					}
				}
			}
		}
	}
	// List Special Offers
	.listSpecialOffers {
		.specialCard {
			.specialWrapper {
				.specialTitle {
					h2 {
						font-size: 32px;
					}
				}
			}
			.bookATestDrivePopUp {
				&.active {
					.popUp {
						padding: 20px;
						.bookATestDrive {
							button {
								&.bookATestDrive {
									width: 100%;
								}
							}
							.form {
								.intro {
									display: flex;
									justify-content: space-between;
									h2 {
										&.left {
											font-size: 32px;
										}
									}
								}
								.preferredDealerField {
									.inputWrapper {
										.currentLocationHolder {
											.currentLocation {
												span {
													font-size: 16px;
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
	.rowSlider {
		.bullets {
			bottom: -5%;
		}
	}
	.sideNavHolder {
		left: 1%;
		width: 99%;
		.mobileSideNavHolder {
			.sideNavMobile {
				overflow: hidden;
				position: absolute;
				bottom: 0;
				padding-bottom: 65px;
			}
		}
		.sideNavItem {
			.content {
				width: auto !important;
				opacity: 1 !important;
				// margin-right: 15px;
				padding-right: 30px !important;
				padding-top: 15px !important;
				&:last-child {
					padding-top: 5px !important;
				}
				p {
					padding: 0;
				}
				.title {
					font-size: 20px;
				}
			}
			&.recall {
				.content {
					height: 50px;
					max-height: 50px;
				}
			}
		}
	}
	.mobileSideNavHolder {
		.menu {
			width: 58px;
			height: 58px;
			position: absolute;
			left: 1%;
			bottom: 1%;
			background-color: $white-grey;
			border: 4px solid $light-grey;
			border-radius: 50%;
			color: $dark-grey;
			font-size: 1.5em;
			line-height: 60px;
			text-align: center;
			display: flex;
			align-items: center;
			justify-content: center;
			transition: 0.3s ease-in-out;
			float: left;
			z-index: 99;
			animation: cog 1.5s infinite;
		}
	}
	.navBuilderHolder {
		.navBuilder {
			.navBuilderItem {
				margin: 0 16px;
			}
		}
	}
	.bookATestDrivePopUp {
		.popUp {
			width: 100%;
			height: 100%;
			padding: 15px;
			.form {
				h2 {
					font-size: 20px;
				}
				.close {
					font-size: 10px;
				}
			}
		}
	}

	.preferredDealerField {
		.inputWrapper {
			.searchLocationHolder {
				width: 80% !important;
			}
			.currentLocationHolder {
				width: 20% !important;
				.currentLocation {
					text-align: center;
					height: 64px;
					padding: 0;
					background-color: $honda-red;
					color: white;
					span {
						display: none;
					}
					svg {
						float: none;
					}
				}
			}
		}
	}

	.bookATestDrive {
		.chooseProducts {
			$rightPosition: 90px;

			.confirmSelection {
				bottom: 10px;
				z-index: 99;
				transition: opacity 0.5s linear;
				&.fixed {
					position: fixed;
					width: 86%;
					margin: 0 auto;
					// right: $rightPosition - 8;
					// margin-right: 10px;
				}
				&.absolute {
					position: absolute;
					width: 100%;
					margin: 0 auto;
					// right: calc(0 - 50%);
					// margin-right: 10px;
				}
			}
		}
		.form {
			.selectedProducts {
				.productCard {
					width: 50%;
					float: left;
					margin-top: 0;
				}
			}

			&.open {
				.selectedProducts {
					.productCard {
						.productTitle {
							h2 {
								font-size: 25px;
							}
						}
					}
				}
				.addMoreProducts {
					width: 100%;
					float: left;
				}
			}
		}
	}
	.inputSliderHolder {
		.inputSlider {
			width: 90%;
		}
	}
	.newsHolder {
		.featuredNewsCard {
			.newsFeatured {
				.title {
					font-size: 25px;
				}
				.category {
					font-size: 18px;
				}
			}
		}
	}
	.smallBanner {
		.productBannerCopyHolder {
			.productBannerCopy {
				.copy {
					h1,
					h2 {
						color: rgba(0, 0, 0, 0.87);
						font-size: 40px;
						line-height: 40px;
						margin-bottom: 0px;
					}
				}
			}
		}
	}
	.productTimelines {
		.productTimelinesHolder {
			.timeline {
				.contentHolder {
					.content {
						margin: unset;
						margin-left: auto;
						width: 77%;
						padding-top: 20px;
						h3 {
							font-size: 16px;
						}
						.copy {
							font-size: 12px;
						}
					}
					.timelineScroll {
						padding: 0;
					}
				}
				.lazyLoad {
					.lazyLoadImg {
						width: auto;
						height: 100%;
					}
					&:after {
						content: " ";
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						background-color: rgba($color: $dark-grey, $alpha: 0.8);
					}
				}
			}
			.timelineSteps {
				left: 0%;
				.item {
					width: 3em;
					.icon {
						width: 3em;
						height: 3em;
						line-height: 3em;
						display: flex;
						justify-content: center;
						align-items: center;
						span {
							font-size: 10px;
						}
						&:after {
							width: 3.6em;
							height: 3.6em;
						}
					}
					&:before {
						height: calc(50% - 1.5em);
					}
					&:after {
						height: calc(50% - 1.5em);
					}
				}
			}
		}
	}
	.campaignBuilderHolder {
		.campaignBuilder {
			.cbTitle {
				font-size: 28px;
				margin: 15px 0;
			}
			// Gallery
			.galleryHolder {
				.scrollarea {
					.scrollarea-content.thumbnailGallery {
						.item {
							padding: 2px;
							border: 0;
							&:nth-child(2),
							&:first-child {
								padding-left: 0;
							}
						}
					}
				}
				.galleryCarousel {
					position: fixed;
					z-index: 9999999999999;
					.close {
						top: 95px !important;
					}
				}
				.thumbnailGallery {
					.item {
						&.itemSize-two {
							height: 50%;
						}
					}
				}
			}
			// Product Colors
			.productColours {
				padding: 40px 0;
				.cbTitle {
					margin: 30px 0 !important;
				}
				.colours {
					.colour {
						width: 40px;
						height: 40px;
						margin: 15px 5px;
					}
				}
				.productColoursHolder {
					.colourHolder {
						width: 100% !important;
						height: 150px !important;
					}
				}
			}
			// Product Features
			.productFeatures {
				.productFeaturesHolder {
					.feature {
						.contentHolder {
							.content {
								width: 70% !important;
								margin-left: 18% !important;
								padding: 8% 0;
								.copy {
									width: 100% !important;
									overflow: hidden !important;
									height: 300px !important;
								}
							}
						}
					}
					.featureImage {
						height: 100% !important;
					}
					.featureSteps {
						left: 0 !important;
						.item {
							width: 2.6em !important;
							&:before {
								left: 38% !important;
								height: calc(50% - 1em) !important;
							}
							&:after {
								left: 38% !important;
								width: 1px;
								height: calc(50% - 1em) !important;
							}
							.icon {
								width: 2em !important;
								height: 2em !important;
								top: 50% !important;
								.custom-icon {
									display: flex;
									align-items: center;
									justify-content: center;
									svg {
										height: 22px;
									}
								}
							}
							// &.item:nth-child(4) {
							//     display: none;
							// }
							// &.item:nth-child(5) {
							//     display: none;
							// }
							// &.item:nth-child(6) {
							//     display: none;
							// }
							&.active {
								.icon {
									&:after {
										width: 2.6em;
										height: 2.6em;
										border-width: 1px;
									}
								}
							}
						}
					}
				}
			}
			//Cars Specifications
			.specificationSection {
				.cbTitle {
					margin: 30px 0;
				}
				.selectTwo {
					display: none;
				}
				.selectThree {
					display: none;
				}
			}

			// Product Models
			.productModels {
				.rowSlider {
					.navItem.navLeft {
						display: none;
					}
					.navItem.navRight {
						display: none;
					}
				}
				.cbTitle {
					margin: 30px 0 !important;
				}
				.productModelsHolder {
					.productModel {
						.modelName {
							top: 10px;
							left: 10px;
							bottom: unset;
						}
					}
				}
			}
		}
	}
}

@media (max-width: 500px) {	
	.productBuilderHolder {
		.productBuilder {
			.productColours {
				.productColoursHolder {
					.colourHolder {
						height: 190px !important;
					}
				}
			}
		}
	}
}



@media (max-width: 400px) {
	.productBuilderHolder {
		.productBuilder {			
			.productColours {				
				.productColoursHolder {
					.colourHolder {
						height: 150px !important;
					}
				}
			}
		}
	}
}
